@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.partners__title {
  margin-bottom: 30px;
}

.partners__subtitle {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 25px;
}

.partners-info__text {
  padding: 62px 110px;
  font-size: 20px;
  background-color: #0B76FE;
  line-height: 28px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 12px;
}
.partners-info__text a {
  color: #ffffff;
  text-decoration: underline;
}

.tabs-link {
  background-color: #F5F5F5;
  border-radius: 50px;
  padding: 12px 24px;
  color: #888888;
  line-height: 22px;
  font-weight: 600;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.tabs-link svg {
  fill: #888888;
  margin-right: 10px;
}

.partners-tabs__list {
  display: flex;
}

.tabs-link:hover,
.tabs-link:hover svg,
.tabs-link.active svg,
.tabs-link.active {
  color: #161717;
  background-color: #67E722;
  fill: #161717;
  transition: all 0.3s ease;
}

.tabs {
  margin: 40px 0;
}
.tabs p:not(:last-child) {
  margin-bottom: 15px;
}

.tabs {
  display: none;
}

.tabs.active {
  display: block;
}

@media (max-width: 1600px) {
  .partners__title {
    margin-bottom: 25px;
  }
  .partners__subtitle {
    font-size: 15px;
  }
  .tabs {
    font-size: 13px;
  }
  .partners-info__text {
    padding: 55px 100px;
    font-size: 16px;
  }
  .tabs-link {
    padding: 8px 24px;
  }
}
@media (max-width: 440px) {
  .partners__subtitle {
    font-size: 16px;
    line-height: 22px;
  }
  .partners-tabs__list {
    flex-wrap: wrap;
  }
  .tabs-link {
    margin-right: 12px;
    padding: 11px 15px;
  }
  .tabs-link:not(:last-child) {
    margin-bottom: 8px;
  }
  .tabs {
    font-size: 15px;
    line-height: 21px;
  }
  .partners-info__text {
    padding: 20px;
    line-height: 22px;
  }
}