@import color
@import fonts


.contacts__title-content
	text-align: center

.contacts__subtitle,
.contacts__description
	font-size: 20px
	line-height: 28px

.contacts__img
	width: 56px
	vertical-align: middle

.contacts__img,
.contacts__subtitle
	margin-bottom: 20px

.contacts__description
	margin-bottom: 40px

.contacts-form
	text-align: center
	box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.07)
	border-radius: 12px
	padding: 40px 80px
	margin-bottom: 40px
	input, select, textarea
		border: 1px solid $color-grey
		border-radius: 12px
		padding: 14px 20px
		font-family: Manrope, sans-serif
		font-size: 15px
	textarea
		width: 100%
		height: 140px
		margin-bottom: 30px
		resize: none
	button
		width: 30%
		font-size: 16px
		margin-bottom: 20px
		cursor: pointer

.input-checkbox
	display: flex
	align-items: center
	justify-content: center
	font-size: 13px
	input
		height: 20px
		width: 20px
		margin-right: 10px
		

.contacts-form__title
	font-size: 20px
	margin-bottom: 10px


.contacts-form__subtitle
	font-size: 15px
	width: 27%
	margin: 0 auto

.contacts-form__content-title
	margin-bottom: 30px

.contacts-form__input-text,
.contacts-form__select
	margin-bottom: 20px

.contacts-form__input-text,
.contacts-form__select,
.contacts__link-row
	display: flex
	justify-content: space-between
	input
		width: calc( 50% - 15px )

.contacts-form__select
	select
		width: calc( 33% - 15px )
	
.contacts__attantion
	margin-bottom: 60px

.contacts__link
	padding: 27px 30px
	width: calc( 50% - 15px )
	background-color: $color-blue
	border-radius: 12px
	display: flex
	align-items: center
	color: $color-white
	font-size: 20px
	font-weight: 600
	position: relative
	transition: all 0.3s ease
	&::after
		content: ""
		position: absolute
		width: 13px
		height: 13px
		background-image: url(/assets/images/svg-icon/cersor.svg)
		transition: all 0.3s ease 
		right: 35px
	&:hover
		background: $color-white
		color: $color-blue
		&::after
			transform: rotate(-135deg)
	&:hover svg
		fill: $color-blue
	svg
		fill: $color-white
		margin-right: 12px
		transition: all 0.3s ease 

.contacts__link-row
	margin-bottom: 40px

.legal-address
	padding: 40px 0
	border-top: 1px solid $color-grey
	border-bottom: 1px solid $color-grey
	text-align: center
	p
		text-transform: uppercase
		&:not(:last-child)
			margin-bottom: 20px
	h4
		&:not(:last-child)
			margin-bottom: 20px

.contacts__images
	position: relative
	overflow: hidden
	display: block
	width: 70px
	margin: auto
	&::after
		content: ""
		display: block
		width: 3px
		height: 100px
		margin-left: 10px
		background: #fff
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%rgba(255, 255, 255, 1) 50% rgba(255, 255, 255, 0.5) 100%)
		left: 0
		top: -20px
		z-index: 1
		transform: rotate(45deg)
		position: absolute
		animation: movingFlare 2s linear 0.2s infinite

			
			
@media ( max-width: 1600px )
	.contacts__img,
	.contacts__subtitle	
		margin-bottom: 15px

	.contacts__subtitle,
	.contacts__description
		font-size: 18px
		line-height: 25px

	.contacts-form__subtitle
		font-size: 13px
		width: 32%

	.contacts-form
		padding: 30px 75px
		margin-bottom: 30px
		input, select, textarea
			padding: 11px 20px
			font-size: 14px
		textarea
			margin-bottom: 25px
		button
			width: 30%
			font-size: 15px
			margin-bottom: 20px

	.contacts-form__input-text,
	.contacts-form__select
		margin-bottom: 20px

	.legal-address
		padding: 35px 0
		p
			font-size: 15px
			text-transform: uppercase
			&:not(:last-child)
				margin-bottom: 15px
		h4
			font-size: 15px
			&:not(:last-child)
				margin-bottom: 15px


	.input-checkbox input
		width: 15px
		height: 15px



@media ( max-width: 440px )
	.contacts-form
		padding: 40px 12px
	
	.contacts-form__title
		font-size: 19px

	.contacts-form__subtitle
		width: 100%
		font-size: 14px

	.contacts-form__input-text,
	.contacts-form__select,
	.contacts__link-row
		flex-direction: column
	
	.contacts-form__input-text input,
	.contacts-form__select input,
	.contacts__link-row input,
	.contacts-form__select select
		width: 100%
		margin-bottom: 10px

	.contacts-form button,
	.contacts__link
		width: 100%

	.input-checkbox
		font-size: 10px

	.contacts-form__input-text,
	.contacts-form__select
		margin-bottom: 0

	.faq__contacts 
		margin-top: 65px

	.contacts__link
		&:not(:last-child)
			margin-bottom: 20px

	.contacts__images::after
		height: 105px
		left: 15px
		top: -24px
			
		

@keyframes movingFlare 
	0% 
		left: -100px

	50% 
		left: 50%

	100%
		left: 110%
		

 
		

