@import fonts
@import color

.guarantees-block
	display: flex
	align-items: center
	padding: 48px 70px
	
.guarantees-block__img
	height: 100px
	margin-right: 45px

.guarantees-block__description
	font-size: 20px
	line-height: 28px
	font-weight: 600

.guarantees-block
	border-radius: 12px
	background-color: $color-Light_grey
	margin-bottom: 55px

.guarantees-list__title-row
	display: flex
	align-items: center
	font-size: 15px
	margin-bottom: 10px
	h5
		font-size: 15px

.guarantees-list__img
	background-color: $color-blue
	width: 32px
	height: 32px
	border-radius: 4px
	display: flex
	align-items: center
	justify-content: center
	margin-right: 13px
	img
		width: 100%
		padding: 3px

.guarantees-list__item
	&:not(:last-child)
		margin-bottom: 20px
	.webmoney-block__span
		margin: 0 5px
	a
		margin: 0 3px
		font-weight: 400
	p
		line-height: 21px


@media (max-width: 1600px)

	.guarantees-block__description
		font-size: 15px
		line-height: 23px

	.guarantees-block
		padding: 35px 70px
		margin-bottom: 50px

	.guarantees-block__img
		height: 95px


	.guarantees-list__title-row
		h5
			font-size: 13px

	.guarantees-list__item
		&:not(:last-child)
			margin-bottom: 15px
		p
			line-height: 21px
			font-size: 12px

	.guarantees-list__img
		width: 25px
		height: 25px

@media (max-width: 440px)

	.guarantees-block
		flex-direction: column
		padding: 30px 16px
		
	.guarantees-block__img
		margin-right: 0
		margin-bottom: 10px

	.guarantees-block__description
		font-size: 16px
		text-align: center

	.guarantees-list__title-row
		align-items: flex-start
		h5
			font-size: 15px

	.guarantees-list__item
		p
			font-size: 15px

	.guarantees-list__img
		width: 32px
		height: 32px
		img
			width: 32px