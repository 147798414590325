@import color
@import fonts

.why-we__content	
	display: flex
	justify-content: space-between
	flex-wrap: wrap

.why-we__item
	width: calc( 25% - 22.5px )
	margin-bottom: 60px

.why-we__icon
	background-color: $color-blue
	box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1)
	border-radius: 4px
	width: 50px
	height: 50px
	display: flex
	align-items: center
	justify-content: center
	margin-bottom: 10px

.why-we__description
	font-weight: 600
	font-size: 14px
	line-height: 19px

.why-we
	padding-bottom: 40px

.why-we-blue
	padding: 80px 0 30px 0
	background-image: url('/assets/images/img-banner/gradient_background.png')
	background-position: center
	background-repeat: no-repeat
	background-size: cover

.why-we-blue__title
	color: $color-white
	text-align: center

.why-we-blue-icon 
	background-color: $color-white
	
.why-we-blue-icon svg
	fill: $color-blue

.why-we-blue .why-we__description
	color: $color-white


@media (max-width: 1600px)
	.why-we__icon
		width: 45px
		height: 45px

	.why-we__description
		font-size: 12px

	.why-we__item
		margin-bottom: 36px

@media (max-width: 1095px)

	.why-we__description
		line-height: 15px

@media (max-width: 690px)
	.why-we__item
		width: calc( 50% - 10px )
		text-align: center

	.why-we__icon
		margin: auto
		margin-bottom: 10px

	.why-we-blue
		padding: 60px 0