@import color
@import fonts


.reviews__title,
.reviews__subtitle
	text-align: center

.reviews__subtitle 
	color: $color-Dark_grey
	font-weight: 600
	font-size: 20px
	width: 34%
	margin: 0px auto
	line-height: 28px
	margin-bottom: 50px

.reviews__title
	margin-bottom: 10px

.reviews-page__content .response-carousel__item
	width: calc( 50% - 15px )
	margin-bottom: 30px
	height: 285px

.reviews-page__content
	display: flex
	justify-content: space-between
	flex-wrap: wrap

.reviews-page__link
	margin-top: 20px
	display: block
	width: 100%
	text-align: center

@media ( max-width: 1600px )

	.reviews__subtitle
		width: 45%
		font-size: 18px

	.reviews-page__content .response-carousel__item
		height: 265px
	

@media ( max-width: 690px )
	.reviews-page__content .response-carousel__item
		width: 100%

	.reviews__subtitle 
		width: 90%
		font-size: 16px
		line-height: 21px

