@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.payment-methods {
  margin-bottom: 100px;
}

.payment-methods__content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.payment-methods-img {
  margin-right: 40px;
  margin-bottom: 15px;
  height: 50px;
}

.footer {
  background-color: #F5F5F5;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DFDFDF;
}

.footer__list {
  display: flex;
  padding-top: 15px;
}

.footer__list-item:not(:last-child) {
  margin-right: 150px;
}

.footer__sublist-link {
  font-size: 14px;
  line-height: 19px;
  color: #161717;
}

.footer__sublist {
  margin-top: 13px;
}

.footer__sublist-item {
  margin-bottom: 2px;
}

.footer__sublist-link-contact {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 27px;
  color: #161717;
}

.footer__sublist-link-contact svg {
  margin-right: 8px;
}

.footer__sublist-item-contact {
  margin-bottom: 10px;
}

.footter__content-bottom {
  font-size: 10px;
  padding: 20px 0 30px 0;
  display: flex;
  justify-content: space-between;
}

.content-bottom__policy--list {
  display: flex;
}

.content-bottom__policy--item:not(:last-child) {
  padding-right: 5px;
  border-right: 1px solid #DFDFDF;
}

.content-bottom__policy--item:not(:first-child) {
  margin-left: 5px;
}

.content-bottom__policy--link {
  color: #161717;
  position: relative;
}

.footer__content img {
  height: 230px;
}

@media (max-width: 1600px) {
  .payment-methods-img {
    margin: 0 14px 14px 0;
    height: 40px;
  }
  .footer__list-item:not(:last-child) {
    margin-right: 100px;
  }
  .footer__sublist-link {
    font-size: 11px;
  }
  .footer__sublist {
    margin-top: 3px;
  }
  .footer__sublist-link-contact {
    font-size: 17px;
  }
  .footter__content-bottom {
    font-size: 7px;
  }
  .footer__sublist-link:hover,
  .footer__sublist-link-contact:hover {
    color: #0B76FE;
    text-decoration: underline;
  }
  .footer__content img {
    height: 200px;
  }
}
@media (max-width: 1090px) {
  .footer__list-item:not(:last-child) {
    margin-right: 47px;
  }
  .footer__content img {
    height: 160px;
  }
  .footer__sublist-item {
    line-height: 19px;
  }
  .footter__content-bottom {
    font-size: 6px;
  }
  .payment-methods-img {
    height: 34px;
  }
}
@media (max-width: 890px) {
  .footer__content img {
    display: none;
  }
  .footter__content-bottom {
    font-size: 8px;
    display: block;
  }
}
@media (max-width: 440px) {
  .payment-methods__content {
    justify-content: flex-start;
  }
  .payment-methods-img {
    height: 30px;
    margin: 0 28px 14px 0;
  }
  .footer__list-item {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .footer__list-item:not(:last-child) {
    margin-right: 0;
  }
  .footer__sublist-link {
    font-size: 14px;
  }
  .footer__sublist-item:not(:last-child) {
    margin-bottom: 10px;
  }
  .footer__sublist-link-contact {
    font-size: 20px;
  }
  .footter__content-bottom {
    font-size: 10px;
    width: 95%;
  }
  .footter__content-bottom,
  .footer__list {
    flex-direction: column;
  }
  .content-bottom__policy--list {
    flex-wrap: wrap;
  }
  .content-bottom {
    margin-bottom: 20px;
  }
  .content-bottom__policy--item:nth-child(3) {
    margin-left: 0;
  }
  .footer__sublist {
    margin-top: 20px;
  }
}