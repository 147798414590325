@import color
@import fonts


.partners__title
	margin-bottom: 30px

.partners__subtitle
	font-size: 20px
	line-height: 28px
	margin-bottom: 25px

.partners-info__text
	padding: 62px 110px
	font-size: 20px
	background-color: $color-blue
	line-height: 28px
	color: $color-white
	font-weight: 600
	border-radius: 12px
	a
		color: $color-white
		text-decoration: underline

.tabs-link
	background-color: $color-Light_grey
	border-radius: 50px
	padding: 12px 24px
	color: $color-Dark_grey
	line-height: 22px
	font-weight: 600
	margin-right: 30px
	display: flex
	align-items: center
	svg
		fill: $color-Dark_grey
		margin-right: 10px

.partners-tabs__list
	display: flex

.tabs-link:hover,
.tabs-link:hover svg,
.tabs-link.active svg,
.tabs-link.active 
	color: $color-black
	background-color: $color-green
	fill: $color-black
	transition: all 0.3s ease

.tabs
	margin: 40px 0
	p
		&:not(:last-child)
			margin-bottom: 15px

.tabs
	display: none

.tabs.active
	display: block

			


@media (max-width: 1600px)
	.partners__title
		margin-bottom: 25px

	.partners__subtitle
		font-size: 15px

	.tabs
		font-size: 13px

	.partners-info__text
		padding: 55px 100px
		font-size: 16px

	.tabs-link
		padding: 8px 24px

@media (max-width: 440px)
	.partners__subtitle 
		font-size: 16px
		line-height: 22px

	.partners-tabs__list
		flex-wrap: wrap

	.tabs-link
		margin-right: 12px
		padding: 11px 15px
		&:not(:last-child)
			margin-bottom: 8px

	.tabs 
		font-size: 15px
		line-height: 21px

	.partners-info__text
		padding: 20px
		line-height: 22px
			