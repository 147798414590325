@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.link {
  color: #0b76fe;
  text-decoration: underline;
  font-weight: 600;
}

.link:hover {
  color: #161717;
}

a {
  transition: all 0.3s ease;
  text-decoration: none;
  color: #161717;
}

.btn {
  padding: 9px 32px;
  border-radius: 25px;
  display: inline-block;
  font-family: Manrope, sans-serif;
  transition: 0.3 all ease;
  line-height: 21px;
  font-weight: 600;
}
.btn:hover {
  color: #67E722;
}

.btn-green, .btn-transparent {
  color: #161717;
}

.btn:hover, .btn-black {
  background-color: #161717;
}

.btn-transparent, .btn-green:active {
  background-color: rgba(0, 0, 0, 0);
}

.btn-transparent, .btn-transparent:hover,
.btn-green, .btn-green:hover, .btn-green:active,
.btn-black, .btn-black:hover {
  border: 2px solid #161717;
}

.btn-transparent:active, .btn-green:active, .btn-black:active,
.btn-grey:hover {
  color: #161717;
}

.btn-black {
  color: #ffffff;
}

.btn-green, .btn-black:active, .btn-transparent:active,
.btn-grey:hover, .btn-grey:active {
  background-color: #67E722;
  border: 2px solid #67E722;
}

.btn-green:hover svg {
  fill: #67E722;
  transition: all 0.3s ease;
}

.btn-grey {
  background-color: #F5F5F5;
  color: #888888;
  border: 2px solid #F5F5F5;
}

@media (max-width: 1600px) {
  .link {
    font-size: 13px;
  }
  .btn {
    padding: 4px 24px;
    font-size: 13px;
  }
}
@media (max-width: 1095px) {
  .btn {
    font-size: 11px;
  }
}
@media (max-width: 440px) {
  .btn {
    font-size: 16px;
    padding: 7px 24px;
  }
}