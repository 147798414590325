@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.key-download-product_title {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
}

.key-download-product__title-row,
.key-download-product__key-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.key-download-product__span-img {
  width: 26px;
  margin-left: 12px;
}

.key-download-product__span {
  padding: 8px 14px;
  background-color: #F5F5F5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.key-download-product__span:first-child {
  margin-right: 12px;
}

.key-download-product__link:first-child {
  margin-right: 10px;
}

.key-download-product__span-row {
  display: flex;
}

.key-download-product__key {
  font-size: 15px;
  line-height: 22px;
}

.key-download-product__link {
  font-size: 16px;
}

.key-download-product__title-row {
  padding-top: 50px;
  margin-bottom: 22px;
  border-top: 1px solid #DFDFDF;
}

.download-product__content {
  display: flex;
  justify-content: space-between;
}

.name-download__product {
  font-size: 20px;
  line-height: 28px;
  padding: 46px 30px;
  border-radius: 12px;
  border: 1px solid #DFDFDF;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.key-download-product {
  padding: 0 30px 30px;
  display: none;
}

.key-download-product.active {
  display: block;
}

.download-product__item.active .name-download__product {
  border: none;
}

.download-product__product-key {
  width: 75%;
}

.name-download__product .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
  background-color: #F5F5F5;
  position: relative;
  transition: all 0.3s ease;
}

.name-download__product .icon::after {
  content: "";
  background-image: url(/assets/images/svg-icon/plus-cross.svg);
  background-repeat: no-repeat;
  display: block;
  width: 16px;
  height: 16px;
}

.download-product__item.active .icon::before {
  transform: rotate(90deg);
}

.download-product__item.active {
  margin-bottom: 15px;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
}

.download-product__item.active .icon {
  transform: rotate(45deg);
  background-color: #67E722;
}

.download-product__list-content {
  width: calc(25% - 30px);
  height: 100%;
}

.download-product__product-item {
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 7px;
}

.download-product__product-item:last-child {
  margin-bottom: 20px;
}

.download-product__product-link.active,
.download-product__product-item.active,
.download-product__product-item.active a {
  color: #0B76FE;
}

.download-product__product-link {
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.download-product__list-content {
  padding: 30px;
  border: 1px solid #DFDFDF;
  border-radius: 12px;
}

.download-product__item:not(:last-child) {
  margin-bottom: 20px;
}

.choise-category {
  display: none;
}

@media (max-width: 1600px) {
  .name-download__product {
    font-size: 18px;
    padding: 30px 25px;
  }
  .key-download-product {
    padding: 0 25px 25px;
  }
  .key-download-product_title,
  .key-download-product__link {
    font-size: 13px;
  }
  .key-download-product__key {
    font-size: 11px;
  }
  .key-download-product__title-row {
    margin-bottom: 15px;
    padding-top: 35px;
  }
  .download-product__product-item {
    font-size: 13px;
  }
  .download-product__product-link {
    margin-bottom: 4px;
  }
  .download-product__product-item:last-child {
    margin-bottom: 15px;
  }
}
@media (max-width: 440px) {
  .choise-category {
    display: block;
    background-color: #0B76FE;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
  .choise-category svg {
    fill: #ffffff;
    transition: all 0.3s ease;
  }
  .choise-category.opened {
    color: #161717;
    border: 1px solid #DFDFDF;
    border-bottom: 0;
    border-radius: 12px 12px 0 0;
    background-color: #ffffff;
    transition: all 0.3s ease;
  }
  .choise-category.opened svg {
    fill: #161717;
    transition: all 0.3s ease;
    transform: rotate(180deg);
  }
  .download-product__list-content {
    border-top: none;
    border-radius: 0 0 12px 12px;
  }
  .download-product__product-key {
    margin-top: 20px;
  }
  .download-product__content {
    flex-direction: column;
  }
  .download-product__list-content,
  .download-product__product-key {
    width: 100%;
  }
  .download-product__list-content {
    display: none;
    padding: 0 20px 30px;
  }
  .download-product__product-link:first-child {
    padding-top: 20px;
    border-top: 1px solid #DFDFDF;
  }
  .name-download__product {
    padding: 25px 20px;
  }
  .key-download-product__title-row {
    flex-direction: column-reverse;
  }
  .key-download-product__span-row {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }
  .key-download-product__title-row {
    padding-top: 10px;
  }
  .key-download-product_title {
    font-size: 14px;
    line-height: 19px;
  }
  .key-download-product__key-content {
    flex-direction: column;
  }
  .key-download-product__link,
  .name-download__product {
    font-size: 16px;
  }
  .key-download-product__link {
    width: 100%;
    text-align: center;
  }
  .key-download-product__link:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .key-download-product__key {
    font-size: 10px;
    margin-bottom: 20px;
  }
}