@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.box-vipkeys__block {
  height: 180px;
  width: calc(50% - 15px);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  padding: 30px 40px;
}

.box-vipkeys__content {
  display: flex;
  justify-content: space-between;
}

.box-vipkeys__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
}

.box-vipkeys__description {
  width: 60%;
  margin-bottom: 13px;
}

.webmoney-block__span {
  background-color: #0B76FE;
  color: #ffffff;
  padding: 4px 16px;
  border-radius: 8px;
}

.webmoney-block__description {
  margin-bottom: 26px;
}

.section-result {
  margin: 60px 0;
}

.section-result__content {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
}

.section-result__content-block:not(:last-child) {
  border-right: 1px solid #DFDFDF;
}

.section-result__content-block {
  width: 33%;
  text-align: center;
  padding: 40px 68px;
}

.section-result__number-row {
  font-weight: 700;
  font-size: 48px;
  line-height: 1;
  font-family: BebasNeue, sans-serif;
  color: #0B76FE;
  margin-bottom: 12px;
}

.section-result__string-row {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.blue-cart__item {
  background-color: #0B76FE;
  color: #ffffff;
  padding: 30px 40px;
  width: calc(50% - 15px);
  margin-bottom: 30px;
  border-radius: 12px;
  display: flex;
  height: 280px;
}

.blue-cart__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
}

.blue-cart__description {
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 74px;
}

.blue-cart__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section-blue-cart {
  margin-bottom: 70px;
}

.blue-cart__link {
  border-color: #ffffff;
  color: #ffffff;
  padding: 8px 32px;
  line-height: 21px;
  font-weight: 600;
}

.blue-cart__link:hover {
  color: #161717;
  background-color: #67E722;
  border-color: #67E722;
}

.blue-cart__content-block {
  width: 44%;
}

.blue-cart__img-block {
  width: 50%;
}

.blue-cart__item:nth-child(1) .blue-cart__img {
  transform: translate(-80px, -16px);
}

.blue-cart__item:nth-child(2) .blue-cart__img {
  transform: translate(41px, -14px);
}

.blue-cart__item:nth-child(3) .blue-cart__img {
  transform: translate(28px, -14px);
}

.blue-cart__item:nth-child(4) .blue-cart__img {
  transform: translate(79px, -14px);
}

.subtitle.about-shop__title {
  font-size: 64px;
  line-height: 1;
  margin-bottom: 28px;
}

.about-shop__description {
  width: calc(50% - 15px);
}

.about-shop__content {
  display: flex;
  justify-content: space-between;
}

.about-shop__left-block-description {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 40px;
}

.about-shop__left-block-link {
  width: calc(50% - 15px);
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  padding: 6px 36px;
}

.about-shop__content--right-block {
  font-size: 16px;
  line-height: 27px;
}

.about-shop__left-block-link-box {
  display: flex;
  justify-content: space-between;
}

.about-shop {
  margin-bottom: 57px;
}

.blog__content-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.blog__item-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.blog__item-img {
  margin-bottom: 20px;
  height: 360px;
}

.blog__item-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.blog__item {
  width: calc(33% - 15px);
}

.blog__item-title {
  margin-bottom: 10px;
  line-height: 22px;
}

.blog__item-description {
  color: #161717;
}

.blog__content {
  display: flex;
  justify-content: space-between;
}

.blog {
  margin-bottom: 100px;
}

.popular-products__link {
  display: none;
}

@media (max-width: 1600px) {
  .box-vipkeys__title {
    font-size: 19px;
    margin-bottom: 3px;
  }
  .box-vipkeys__block {
    padding: 31px 32px;
    height: 155px;
  }
  .box-vipkeys__description {
    font-size: 13px;
    margin-bottom: 3px;
    height: 35px;
  }
  .webmoney-block__span {
    padding: 4px 12px;
    font-size: 13px;
  }
  .section-result {
    margin: 35px;
  }
  .section-result__number-row {
    font-size: 37px;
  }
  .section-result__string-row {
    font-size: 12px;
  }
  .blue-cart__item {
    height: 222px;
    padding: 23px 32px;
  }
  .blue-cart__title {
    font-size: 19px;
    margin-bottom: 4px;
  }
  .blue-cart__link {
    padding: 4px 24px;
  }
  .blue-cart__description {
    font-size: 12px;
    margin-bottom: 60px;
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    width: 100%;
    transform: translate(16px, -7px);
  }
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(-51px, 0px);
    width: 145%;
  }
  .blue-cart__content-block {
    width: 45%;
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    width: 100%;
    transform: translate(27px, -8px);
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(67px, -8px);
    width: 80%;
  }
  .section-blue-cart {
    margin-bottom: 45px;
  }
  .item-serves .blue-cart__description {
    margin-bottom: 46px;
  }
  .about-shop__left-block-description {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 32px;
  }
  .about-shop__left-block-link {
    padding: 4px 18px;
  }
  .about-shop__content--right-block {
    font-size: 13px;
    line-height: 19px;
  }
  .about-shop {
    margin-bottom: 42px;
  }
  .subtitle.about-shop__title {
    font-size: 51px;
  }
  .blog__item-img {
    height: 260px;
  }
  .blog__item-title:hover {
    color: #0B76FE;
  }
}
@media (max-width: 1090px) {
  .blog__item-img {
    height: 200px;
  }
  .about-shop__content--right-block {
    line-height: 17px;
  }
  .about-shop__left-block-description {
    line-height: 20px;
  }
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(24px, 16px);
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(49px, 18px);
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    transform: translate(-28px, 28px);
  }
  .block-microsoft p {
    margin-bottom: 44px;
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    transform: translate(12px, 24px);
  }
  .section-result__content-block {
    padding: 40px 45px;
  }
  .box-vipkeys__block {
    padding: 20px;
  }
  .box-vipkeys__title {
    font-size: 18px;
  }
  .box-vipkeys__description {
    width: 70%;
  }
}
@media (max-width: 890px) {
  .box-vipkeys__title {
    font-size: 15px;
  }
  .box-vipkeys__block {
    height: 145px;
  }
  .section-result__content-block {
    padding: 23px;
  }
  .blue-cart__content-block {
    width: 60%;
  }
  .block-microsoft p,
  .item-serves .blue-cart__description {
    margin-bottom: 0;
  }
  .blue-cart__link {
    line-height: 15px;
  }
  .blue-cart__description {
    font-size: 11px;
    height: 85px;
    margin-bottom: 0;
  }
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(-29px, 39px);
  }
  .blue-cart__item {
    height: 200px;
    padding: 20px 30px;
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    transform: translate(12px, 16px);
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    transform: translate(6px, 26px);
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(39px, 19px);
  }
  .blog__item-img {
    height: auto;
  }
  .about-shop__left-block-link.btn {
    padding: 4px 8px;
    font-size: 10px;
  }
  .about-shop__content--right-block {
    font-size: 11px;
  }
  .about-shop__left-block-description {
    font-size: 14px;
  }
}
@media (max-width: 690px) {
  .box-vipkeys__content {
    flex-direction: column;
  }
  .box-vipkeys__block {
    width: 100%;
    height: 180px;
  }
  .box-vipkeys__vipkeys-block {
    margin-bottom: 20px;
  }
  .box-vipkeys__title {
    font-size: 19px;
    margin-bottom: 15px;
  }
  .section-result__content {
    flex-direction: column;
  }
  .section-result__content-block {
    width: 100%;
    padding: 50px 0;
  }
  .section-result__content-block:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid #DFDFDF;
  }
  .section-result__number-row {
    font-size: 48px;
  }
  .section-result__string-row {
    font-size: 15px;
  }
  .blue-cart__item {
    width: 100%;
    flex-direction: column;
    height: 280px;
  }
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(207px, -96px);
    width: 62%;
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    transform: translate(277px, -127px);
    width: 42%;
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    transform: translate(302px, -134px);
    width: 38%;
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(362px, -125px);
    width: 30%;
  }
  .blue-cart__img-block {
    width: 100%;
  }
  .subtitle.about-shop__title {
    font-size: 32px;
  }
  .about-shop__content {
    flex-direction: column;
  }
  .about-shop__description {
    width: 100%;
  }
  .about-shop__content {
    position: relative;
    padding-bottom: 110px;
  }
  .about-shop__left-block-link-box {
    position: absolute;
    bottom: 10px;
    width: 100%;
    flex-direction: column;
  }
  .about-shop__left-block-link {
    width: 100%;
  }
  .about-shop__left-block-link:first-child {
    margin-bottom: 10px;
  }
  .about-shop__left-block-description {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .about-shop__content--right-block {
    font-size: 14px;
  }
  .blog__item-title {
    line-height: 17px;
  }
}
@media (max-width: 550px) {
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(163px, -74px);
    width: 62%;
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    transform: translate(207px, -106px);
    width: 47%;
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    transform: translate(187px, -113px);
    width: 51%;
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(227px, -97px);
    width: 38%;
  }
  .blog__content {
    flex-direction: column;
  }
  .blog__item {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 440px) {
  .box-vipkeys__vipkeys-block {
    margin-bottom: 20px;
  }
  .box-vipkeys__title {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 22px;
  }
  .box-vipkeys__description {
    width: 100%;
    margin-bottom: 20px;
  }
  .section-result__content {
    flex-direction: column;
  }
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(68px, -46px);
    width: 87%;
  }
  .section-result {
    margin: 50px 0;
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    transform: translate(97px, -53px);
    width: 63%;
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    transform: translate(137px, -79px);
    width: 60%;
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(162px, -26px);
    width: 40%;
  }
  .blue-cart__content-block {
    width: 214px;
  }
  .blue-cart__link {
    display: none;
  }
  .section-result__content {
    padding: 0;
  }
  .blue-cart__content-block {
    width: 214px;
  }
  .blue-cart__title {
    font-size: 16px;
  }
  .blue-cart__description {
    font-size: 14px;
  }
  .popular-products__link {
    display: block;
    text-align: center;
  }
  .blog__item-img {
    height: 290px;
  }
  .blog__item-title {
    font-size: 16px;
  }
  .blog__item-description.span-dark-grey {
    color: #161717;
  }
  .blog__content-link.link {
    bottom: 38px;
    left: 22%;
    border: 2px solid #161717;
    color: #161717;
    text-decoration: none;
    font-size: 16px;
    border-radius: 50px;
    padding: 6px 32px;
    position: absolute;
  }
  .blog .container {
    position: relative;
    padding-bottom: 100px;
  }
}
@media (max-width: 385px) {
  .blue-cart__item:nth-child(1) .blue-cart__img {
    transform: translate(10px, -26px);
    width: 101%;
  }
  .blue-cart__item:nth-child(2) .blue-cart__img {
    transform: translate(81px, -46px);
    width: 72%;
  }
  .blue-cart__item:nth-child(3) .blue-cart__img {
    transform: translate(96px, -35px);
    width: 65%;
  }
  .blue-cart__item:nth-child(4) .blue-cart__img {
    transform: translate(98px, -30px);
    width: 50%;
  }
}