@import color
@import fonts

.carousel-hero__item
	display: flex
	justify-content: space-between

.carousel-hero__content
	width: 50%

.carousel-hero__h1
	font-weight: 700
	line-height: 110%
	font-size: 64px

.carousel-hero__h1, .carousel-hero__title
	font-family: BebasNeue, sans-serif
	margin-bottom: 10px
	margin-top: 0

.carousel-hero__title 
	font-weight: 400
	line-height: 63.8px
	font-size: 58px

.carousel-hero__description
	margin-top: 0
	font-size: 20px
	line-height: 27px
	color: $color-Dark_grey
	margin-bottom: 25px

.carousel-hero__link-more
	padding: 7px 32px
	font-weight: 600

.hero-carousel .owl-carousel .owl-dots
	display: block
	position: absolute
	bottom: 22px
	left: 0

.hero-carousel .owl-carousel button.owl-dot.active
	background: $color-blue
	width: 24px
	height: 8px
	border-radius: 24px

.hero-carousel .owl-carousel button.owl-dot
	width: 8px
	height: 8px
	background: $color-grey
	border-radius: 50%
	margin-right: 8px

.carousel-hero
	position: relative

.price-span-blue
	font-weight: 800
	font-size: 32px
	line-height: 44px
	color: $color-blue

.slide-first
	margin-top: 80px

.slide-next
	margin-top: 30px
	align-items: center


@media (max-width: 1600px)
	.carousel-hero__h1
		font-size: 51px

	.slide-first
		margin-top: 57px

	.carousel-hero__description
		font-size: 16px

	.carousel-hero__link-more
		font-size: 13px
		padding: 4px 24px

	.carousel-hero__title
		font-size: 42px
		line-height: 55px

	.carousel-hero__img img 
		height: 350px

	.hero-carousel .owl-carousel .owl-dots
		bottom: 42px

	.hero-carousel
		height: 380px

@media (max-width: 1095px)

	.slide-first
		margin-top: 40px

	.carousel-hero__h1
		font-size: 40px

	.carousel-hero__img img
		height: 320px

	.carousel-hero__title
		font-size: 33px
		line-height: 40px

	.hero-carousel .owl-carousel .owl-dots
		bottom: 10px

	.price-span-blue
		font-size: 29px

@media (max-width: 890px)
	.slide-first 
		margin-top: 20px

	.slide-next
		margin-top: 0

	.hero-carousel 
		height: 300px

	.carousel-hero__img 
		img 
			height: 280px

	.carousel-hero__title
		font-size: 30px

	.price-span-blue 
		font-size: 24px

@media (max-width: 690px)
	.hero-carousel .owl-carousel .owl-dots
		left: 42%
		bottom: -55px

	.carousel-hero__title
		font-size: 24px

	.carousel-hero__item
		display: flex
		flex-direction: column-reverse

	.carousel-hero__img img 
		height: 230px
		object-fit: contain

	.carousel-hero__content
		width: 100%
		text-align: center

	.hero-carousel
		height: 520px

@media (max-width: 440px)

	.carousel-hero__h1
		font-size: 32px

	.carousel-hero__link-more
		font-size: 16px

	.carousel-hero__description
		margin-bottom: 20px

	.hero-carousel
		height: 550px

	.hero-carousel .owl-carousel button.owl-dot
		margin-right: 12px

	.hero-carousel .owl-carousel .owl-dots
		left: 34%

	.carousel-hero__title
		font-family: Manrope, sans-serif
		font-size: 20px
		
		margin-bottom: 0

	.carousel-hero__title b
		font-family: BebasNeue, sans-serif
		font-size: 48px
		line-height: 54px

	.slide-first
		margin-top: 10px