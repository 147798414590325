@import color
@import fonts


.faq__contacts
	form
		margin-top: 100px
	.contacts__description
		margin-bottom: 0

.faq-tabs-link
	display: flex
	align-items: center
	padding: 13px 18px
	transition: all 0.3s ease
	&:hover
		background-color: $color-green
		color: $color-black
		transition: all 0.3s ease
		svg
			fill: $color-black
			transition: all 0.3s ease
	svg
		fill: $color-Dark_grey
		margin-right: 10px
		transition: all 0.3s ease
	&:not(:last-child)
		margin-right: 25px 
		
.faq-tabs-link.active
	background-color: $color-green
	color: $color-black
	svg
		fill: $color-black

.faq__tab
	display: none

.faq__tab.active
	display: block

.faq__tab-link-content
	display: flex
	margin-bottom: 40px

.faq__tab-item
	padding: 20px
	width: 100%
	margin-bottom: 15px
	border: 1px solid #dfdfdf
	border-radius: 8px

.question-faq
	display: flex
	align-items: center
	justify-content: space-between
	cursor: pointer

.answer-faq
	display: none
	padding-top: 20px
	border-top: 1px solid $color-grey

.faq__tab-item.active .question-faq
	padding-bottom: 20px

.question-faq .icon
	width: 40px
	height: 40px
	display: flex
	align-items: center
	justify-content: center
	border-radius: 50%
	font-size: 30px
	background-color: #f5f5f5
	position: relative
	transition: .3s

.faq__tab-item.active .icon 
	transform: rotate(45deg)
	background-color: #67e722


.question-faq .icon::after 
	content: ""
	background-image: url(/assets/images/svg-icon/plus-cross.svg)
	background-repeat: no-repeat
	display: block
	width: 16px
	height: 16px

@media ( max-width: 1600px )

	.faq-tabs-link
		padding: 8px 14px
		font-size: 12px
		svg
			margin-right: 5px
		&:not(:last-child)
			margin-right: 15px 

	.question-faq
		font-size: 14px

	.faq__tab-item
		padding: 15px

	.answer-faq
		font-size: 13px
		padding-top: 15px

	.faq__tab-item.active .question-faq
		padding-bottom: 15px

	.question-faq .icon
		width: 35px
		height: 35px

	.faq__contacts form
		margin-top: 75px 

@media ( max-width: 440px )

		.question-faq
			width: 90%

		.question-faq .icon
			position: absolute
			right: 30px

		.faq__tab-link-content
			flex-direction: column
			margin-bottom: 20px

		.faq-tabs-link
			margin-bottom: 10px
			font-size: 14px
			width: max-content
			&:not(:last-child)	
				margin-right: 0

		.contacts__description,
		.contacts__subtitle
			font-size: 15px
