@import color
@import fonts

// product-item

.product-item__img
	width: 205px
	
.product-item__img-block 
	padding: 41px 48px
	border: 1px solid #dfdfdf
	border-radius: 12px
	margin-bottom: 15px
	display: block

.product-item__title
	font-size: 15px
	font-weight: 500
	line-height: 21px
	margin-bottom: 10px
	height: 45px
	overflow: hidden

.product-item
	width: calc( 25% - 15px )
	&:not(:nth-child(4n))
		margin-right: 20px


.product-item__info
	width: 80%

.product-item__now-price
	font-weight: 800
	font-size: 24px
	line-height: 33px
	letter-spacing: -0.05em
	margin-right: 20px

.product-item__reviews
	display: flex
	align-items: center
	margin-bottom: 15px

.product-item__description
	font-size: 15px
	line-height: 21px
	height: 41px
	overflow: hidden
	margin-bottom: 15px

.product-item__review
	color: $color-Dark_grey

.product-item__reviews-icon
	margin-right: 6px


.product-item__old-price
	font-size: 20px
	line-height: 27px
	letter-spacing: -0.05em
	text-decoration-line: line-through
	color: $color-Dark_grey
	
.product-item__price-block
	display: flex
	align-items: center
	margin-bottom: 10px



//  section-carousel popular-prodect

.popular-products__carousel.owl-carousel .owl-item img 
	width: 205px
	object-fit: contain

.popular-products__carousel.owl-carousel .owl-nav.disabled
	display: block

.popular-products__carousel
	position: relative

.popular-products__carousel.owl-carousel .owl-nav button.owl-prev,
.popular-products__carousel.owl-carousel .owl-nav button.owl-next
	position: absolute
	top: 26%
	transition: all 0.3s ease
	svg
		fill: $color-grey
		&:hover
			svg
				fill: $color-black
				transition: all 0.3s ease
	
.popular-products__carousel.owl-carousel .owl-nav button.owl-prev
	left: -56px

.popular-products__carousel.owl-carousel .owl-nav button.owl-next
	right: -56px

.popular-products__carousel .product-item__description
	display: none

.popular-products__carousel .product-item
	width: 100%





// new-product-carousel section


.new-products__carousel .product-item__title
	font-weight: 600
	font-size: 20px
	line-height: 28px
	margin-bottom: 44px
	height: auto

.new-products__carousel .product-item
	display: flex
	justify-content: space-between
	height: 300px
	padding: 40px 20px 
	border: 1px solid $color-grey
	border-radius: 12px
	width: 100%
	align-items: center

.new-products__carousel .product-item__description
	display: none

.new-products__carousel .product-item__img-block
	border: none
	padding: 0
	margin-bottom: 0

.new-products__carousel .product-item__info
	width: 55%
	
.new-products__carousel.owl-carousel .owl-nav.disabled
	display: block

.new-products__carousel.owl-carousel .owl-nav button.owl-prev,
.new-products__carousel.owl-carousel .owl-nav button.owl-next
	position: absolute
	top: 50%
	transition: all 0.3s ease
	svg
		fill: $color-grey
	&:hover
		svg
			fill: $color-black
			transition: all 0.3s ease
	
.new-products__carousel.owl-carousel .owl-nav button.owl-prev
	left: -56px

.new-products__carousel.owl-carousel .owl-nav button.owl-next
	right: -56px




@media (max-width: 1600px)

	.popular-products__carousel.owl-carousel .owl-item img
		width: 160px

	.product-item__img-block
		padding: 33px 35px

	.popular-products__carousel.owl-carousel .owl-nav button.owl-prev 
		left: -46px

	.popular-products__carousel.owl-carousel .owl-nav button.owl-next
		right: -46px

	.popular-products__carousel.owl-carousel .owl-nav button.owl-next, .popular-products__carousel.owl-carousel .owl-nav button.owl-prev
		top: 23%

	.product-item__title
		height: 35px
		font-size: 12px
		line-height: 16px
		margin-bottom: 6px

	.product-item__img-block
		margin-bottom: 10px

	.product-item__now-price
		font-size:  21px
		margin-right: 14px

	.product-item__old-price
		font-size: 16px

	.product-item__price-block
		margin-bottom: 3px

	.product-item__reviews
		margin-bottom: 9px

	.new-products__carousel.owl-carousel .owl-item img
		width: 175px

	.new-products__carousel .product-item
		height: 230px

	.new-products__carousel .product-item__title
		font-size: 15px
		margin-bottom: 25px

	.product-item__img
		width: 160px

	.product__reviews:hover
		color: $color-blue
		text-decoration: underline

@media (max-width: 1095px)
	.new-products__carousel .product-item
		padding: 20px

	.product-item__img-block 
		padding: 31px 27px
		margin-bottom: 10px

	.product-item__img
		width: 125px

	
@media (max-width: 890px)

	.popular-products__carousel.owl-carousel .owl-nav
		display: none

	.new-products__carousel.owl-carousel .owl-nav.disabled
		display: none

	.popular-products__carousel.owl-carousel .owl-item img
		width: 130px

	.new-products__carousel.owl-carousel .owl-item img
		width: 140px

	.product-item__now-price
		font-size: 18px

	.product-item__img-block
		padding: 27px 30px

	.product-item__old-price
		font-size: 12px

	.new-products__carousel .product-item__title
		font-size: 13px
		line-height: 21px


@media (max-width: 690px)
	.new-products__carousel.owl-carousel,
	.popular-products__carousel.owl-carousel
		display: flex
		flex-wrap: wrap
		justify-content: space-between

	.new-products__carousel .product-item__img-block
		border: 1px solid $color-grey
		padding: 30px 64px 
		border-radius: 12px
		margin-bottom: 10px

	.product-item__img-block 
		padding: 30px 20px

	.product-item__img 
		width: 120px

	.product-item__info,
	.new-products__carousel .product-item__info 
		width: 100%

	.product-item__link
		width: 100%
		text-align: center

	.product-item__now-price
		font-size: 20px

	.product-item__old-price
		font-size: 16px

	.product-item__title,
	.new-products__carousel .product-item__title
		font-size: 14px
		line-height: 19px
		margin-bottom: 6px

	.new-products__carousel .product-item
		flex-direction: column
		padding: 0
		border: none
		height: auto

	.product-item__img
		width: 160px

	.product-item
		width: 100%

	.product-item__link
		width: 50%

	.product-item__img-block 
		padding: 20px 50px


	.new-products__carousel.owl-carousel .product-item,
	.popular-products__carousel.owl-carousel .product-item
		width: calc( 50% - 10px )
		margin-bottom: 20px

	.new-products__carousel.owl-carousel .product-item__img,
	.popular-products__carousel.owl-carousel .product-item__img
		width: 140px

	.new-products__carousel.owl-carousel .product-item__link,
	.popular-products__carousel.owl-carousel .product-item__link
		width: 100%

	.popular-products__carousel.owl-carousel .product-item__img-block
		padding: 30px 15px

@media (max-width: 550px)
	.new-products__carousel .product-item__img-block
		padding: 30px 10px 

	.product-item__img-block 
		padding: 20px 28px

	.product-item__img 
		width: 135px


@media (max-width: 440px)

	.product-item__img-block
		padding: 38px 52px

	.product-item__img,
	.new-items .new-products__carousel.owl-carousel .product-item__img
		width: 225px !important

	.new-products__carousel.owl-carousel .product-item__img,
	.popular-products__carousel.owl-carousel .product-item__img
		width: 140px !important

	.new-products__carousel.owl-carousel .product-item,
	.popular-products__carousel.owl-carousel .product-item
		margin-right: 0
