@import color
@import fonts

.link
	color: #0b76fe
	text-decoration: underline
	font-weight: 600

.link:hover
	color: $color-black
a
	transition: all .3s ease
	text-decoration: none
	color: $color-black
.btn
	padding: 9px 32px
	border-radius: 25px
	display: inline-block
	font-family: Manrope, sans-serif
	transition: 0.3 all ease
	line-height: 21px
	font-weight: 600
	&:hover
		color: $color-green

.btn-green, .btn-transparent
	color: $color-black

.btn:hover, .btn-black 
	background-color: $color-black

.btn-transparent, .btn-green:active
	background-color: #00000000

.btn-transparent, .btn-transparent:hover, 
.btn-green, .btn-green:hover, .btn-green:active,
.btn-black, .btn-black:hover
	border: 2px solid $color-black

.btn-transparent:active, .btn-green:active, .btn-black:active,
.btn-grey:hover
	color: $color-black

.btn-black
	color: $color-white
		
.btn-green, .btn-black:active, .btn-transparent:active,
.btn-grey:hover, .btn-grey:active
	background-color: $color-green
	border: 2px solid $color-green

.btn-green:hover svg
	fill: $color-green
	transition: all .3s ease

.btn-grey
	background-color: $color-Light_grey
	color: $color-Dark_grey
	border: 2px solid $color-Light_grey

@media (max-width: 1600px)
	.link
		font-size: 13px

	.btn
		padding: 4px 24px
		font-size: 13px

@media (max-width: 1095px)
	.btn
		font-size: 11px

@media (max-width: 440px)
	.btn
		font-size: 16px
		padding: 7px 24px





		
		