@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.reviews__title,
.reviews__subtitle {
  text-align: center;
}

.reviews__subtitle {
  color: #888888;
  font-weight: 600;
  font-size: 20px;
  width: 34%;
  margin: 0px auto;
  line-height: 28px;
  margin-bottom: 50px;
}

.reviews__title {
  margin-bottom: 10px;
}

.reviews-page__content .response-carousel__item {
  width: calc(50% - 15px);
  margin-bottom: 30px;
  height: 285px;
}

.reviews-page__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reviews-page__link {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}

@media (max-width: 1600px) {
  .reviews__subtitle {
    width: 45%;
    font-size: 18px;
  }
  .reviews-page__content .response-carousel__item {
    height: 265px;
  }
}
@media (max-width: 690px) {
  .reviews-page__content .response-carousel__item {
    width: 100%;
  }
  .reviews__subtitle {
    width: 90%;
    font-size: 16px;
    line-height: 21px;
  }
}