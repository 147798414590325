@import fonts
@import color

.windows__choice-link
	background-color: $color-Light_grey
	border-radius: 50px
	padding: 12px 24px
	color: $color-Dark_grey
	line-height: 22px
	font-weight: 600

.windows__choice-link:hover,
.windows__choice-link:active
	color: $color-black
	background-color: $color-green
	transition: all 0.3s ease

.windows__choice-item
	&:not(:last-child)
		margin-right: 20px

.windows__choice-list
	display: flex
	align-items: center
	flex-wrap: wrap
	margin-bottom: 50px

.windows__sorting-title
	font-size: 20px
	line-height: 28px

.windows__sorting-label
	margin-right: 20px

#sorting-products
	font-size: 15px
	padding: 15px 20px
	width: 300px
	line-height: 21px
	border: 1px solid $color-grey

.windows__sorting
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: 20px

// all products

.all-products .product-item
	margin-bottom: 30px

.all-products__content
	display: flex
	flex-wrap: wrap

@media (max-width: 890px)

	.windows__choice-item
		margin-bottom: 10px

	.windows__sorting-row
		width: 50%

@media (max-width: 690px)

	.response .container 
		position: relative
		padding-bottom: 100px

	.all-products .product-item 
		width: 48%

	.all-products .product-item
		&:not(:nth-child(4n))
			margin-right: 0

	.all-products .product-item
		&:not(:nth-child(2n))
			margin-right: 20px

@media (max-width: 550px)


@media (max-width: 440px)

	.windows__choice-link
		font-size: 14px
		padding: 8px 16px

	.windows__choice-item
		margin-bottom: 8px
		&:not(:last-child)
			margin-right: 12px

	.windows__choice-list
		justify-content: flex-start
		margin-bottom: 20px


	.windows__sorting-title
		display: none

	.windows__sorting-row
		display: flex
		align-items: center

	#sorting-products
		width: 170px

	.windows__sorting-label
		margin-right: 55px

	.response .response__link
		bottom: 30px

	.all-products .product-item
		width: 100%

	.all-products .product-item
		&:not(:nth-child(2n))
			margin-right: 0

