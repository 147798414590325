@import color
@import fonts


.product-not-found__img
	width: 100%
	height: 380px
	object-fit: contain

.product-not-found__content
	display: flex
	justify-content: space-between

.product-not-found__description.span-dark-grey
	font-size: 16px
	margin-bottom: 40px

.product-not-found__link
	width: 50%
	text-align: center
	&:first-child
		margin-right: 15px

.product-not-found__link-block 
	display: flex

.product-not-found__text-content
	width: 45%

@media (max-width: 1090px)

	.product-not-found__img
		height: 250px

	.product-not-found__description.span-dark-grey
		font-size: 13px

	.product-not-found__text-content
		width: 50%

@media (max-width: 890px)
	.product-not-found__text-content 
		width: 56%

	.product-not-found__img
		height: 218px

@media (max-width: 690px)

	.product-not-found__content
		position: relative

	.product-not-found__img-content
		position: absolute
		right: 38px
		top: -18px

	.product-not-found__text-content
		width: 100%

	.product-not-found__img
		height: 135px

@media (max-width: 550px)
	.product-not-found__link-block
		flex-direction: column

	.product-not-found__link:first-child 
		margin-right: 15px

	.product-not-found__link
		margin-bottom: 10px

	.product-not-found__description.span-dark-grey
		padding-bottom: 300px

	.product-not-found__img
		height: 285px

	.product-not-found__link
		width: 100%

	.product-not-found__img-content 
		top: 141px
		left: 0
		width: 100%