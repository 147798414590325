@import color
@import fonts

.breadcrumb
	padding-top: 20px
	border-top: 1px solid $color-grey
	margin-top: 20px
	margin-bottom: 40px

.breadcrumb-list
	display: flex
	align-items: center
	
.breadcrumb-item
	font-size: 14px
	line-height: 19px
	position: relative

.breadcrumb-item
		&:not(:last-child)
			padding-right: 15px
			&::after
				content: ""
				position: absolute
				top: 3px
				right: -3px
				background-image: url('/assets/images/svg-icon/Chevron-right.svg')
				width: 7px
				height: 12px

.breadcrumb-item
	&:not(:first-child)
		padding-left: 15px

@media (max-width: 1600px)
	.breadcrumb-item
		font-size: 12px

@media (max-width: 890px)
	.breadcrumb
		margin-top: 0

@media (max-width: 440px)
	.breadcrumb 
		border: none

	.breadcrumb-item,
	.breadcrumb-item.span-dark-grey
		font-size: 13px

	.breadcrumb-item
		&:nth-child(4)
			padding-left: 0 !important
		&:not(:last-child)
			&::after 
				top: 5px
				width: 5.5px
				height: 9px
				background-size: contain
				background-repeat: no-repeat
			

	.breadcrumb-item:not(:first-child)
		padding-left: 12px

	.breadcrumb-item:not(:last-child)
		padding-right: 12px

	.breadcrumb-list
		flex-wrap: wrap

	




