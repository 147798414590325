@font-face
	font-family: "Manrope"
	src : url('../fonts/Manrope/Manrope-Light.woff') format("woff")
	src : url('../fonts/Manrope/Manrope-Light.woff2') format("woff2")
	font-weight: 400
	font-style: normal

@font-face
	font-family: "Manrope"
	src : url('../fonts/Manrope/Manrope-Medium.woff') format("woff")
	src : url('../fonts/Manrope/Manrope-Medium.woff2') format("woff2")
	font-weight: 500
	font-style: normal

@font-face
	font-family: "Manrope"
	src : url('../fonts/Manrope/Manrope-SemiBold.woff') format("woff")
	src : url('../fonts/Manrope/Manrope-SemiBold.woff2') format("woff2")
	font-weight: 600
	font-style: normal

@font-face
	font-family: "Manrope"
	src : url('../fonts/Manrope/Manrope-Bold.woff') format("woff")
	src : url('../fonts/Manrope/Manrope-Bold.woff2') format("woff2")
	font-weight: 700
	font-style: normal

@font-face
	font-family: "Manrope"
	src : url('../fonts/Manrope/Manrope-ExtraBold.woff') format("woff")
	src : url('../fonts/Manrope/Manrope-ExtraBold.woff2') format("woff2")
	font-weight: 800
	font-style: normal

@font-face
	font-family: "BebasNeue"
	src : url('../fonts/Bebas/BebasNeueLight.woff') format("woff")
	src : url('../fonts/Bebas/BebasNeueLight.woff2') format("woff2")
	font-weight: 400
	font-style: normal

// @font-face
// 	font-family: "BebasNeue"
// 	src : url('../fonts/Bebas/BebasNeueBook.woff') format("woff")
// 	src : url('../fonts/Bebas/BebasNeueBook.woff2') format("woff2")
// 	font-weight: 400
// 	font-style: normal

@font-face
	font-family: "BebasNeue"
	src : url('../fonts/Bebas/BebasNeueRegular.woff') format("woff")
	src : url('../fonts/Bebas/BebasNeueRegular.woff2') format("woff2")
	font-weight: 500
	font-style: normal


@font-face
	font-family: "BebasNeue"
	src : url('../fonts/Bebas/BebasNeueBold.woff') format("woff")
	src : url('../fonts/Bebas/BebasNeueBold.woff2') format("woff2")
	font-weight: 700
	font-style: normal


