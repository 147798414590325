@import fonts
@import color


.good-luck__with-vipkeys__text
	color: $color-white
	padding: 115px 
	text-align: center
	margin: 0
	background-image: url('/assets/images/img-banner/gradient_background.png')
	background-repeat: no-repeat
	background-size: cover
	border-radius: 12px
	position: relative
	overflow: hidden
	&::after
		content: ""
		display: block
		width: 5px
		height: 405px
		margin-left: 10px
		background: #fff
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%rgba(255, 255, 255, 1) 50% rgba(255, 255, 255, 0.5) 100%)
		left: 60px
		top: -57px
		z-index: 1
		transform: rotate(45deg)
		position: absolute
		animation: movingFlare 2s linear 0.2s infinite



@media (max-width: 1600px)
	.good-luck__with-vipkeys__text
		padding: 95px

@media (max-width: 440px)
	
	.good-luck__with-vipkeys__text.subtitle
		font-size: 24px
		padding: 76px 12px

	.good-luck__with-vipkeys__text
		&::after
			height: 295px
			top: -46px
			animation: 2s linear 2s infinite movingFlare
	
		