@import color
@import fonts

// section category

.main-category__item
	width: calc( 50% - 15px )
	border: 1px solid $color-grey
	border-radius: 12px
	margin-bottom: 30px
	padding: 20px

.main-category__link
	display: flex
	align-items: center
	font-weight: 600
	font-size: 20px
	position: relative

.main-category__img-block
	width: 80px
	height: 80px
	display: flex
	align-items: center
	justify-content: center
	padding: 10px
	background: #F5F5F5
	border-radius: 8px
	margin-right: 20px
	

.main-category__img-block,
.main-category__item,
.main-category__link,
.main-category__item:hover .main-category__link,
.main-category__item:hover .main-category__img-block,
.main-category__item:hover
	transition: all 0.3s ease

.main-category__list
	display: flex
	justify-content: space-between
	flex-wrap: wrap

.main-category__item:hover 
	background-color: $color-blue
	
.main-category__item:hover .main-category__img-block,
.main-category__item:hover
	border-radius: 100px

.main-category__item:hover .main-category__img-block
	background-color: $color-white

.main-category__item:hover .main-category__link
	color: $color-white

.main-category__item:hover .main-category__link:after
	position: absolute
	content: ""
	right: 7%
	top: 44%
	width: 18px
	height: 18px
	background-image: url('/assets/images/svg-icon/cersor.svg')
	background-repeat: no-repeat
	transition: all 0.3s ease

.hit-items__content
	display: flex
	justify-content: space-between

@media (max-width: 890px)
	.main-category__link
		font-size: 16px

@media (max-width: 690px)
	.hit-items .product-item
		width: calc( 50% - 15px )
		margin-bottom: 20px

	.hit-items__content
		flex-wrap: wrap
		
@media (max-width: 550px)
	.main-category__item
		width: 100%
		margin-bottom: 12px
		padding: 12px

@media (max-width: 440px)
	.main-category__item:hover, .main-category__item:hover
		border-radius: 12px

	.main-category__img-block
		width: 60px
		height: 60px
		margin-right: 12px

	.main-category__item:hover .main-category__link:after
		content: none

	.main-category__img
		width: 32px

	.main-category__link
		font-size: 15px

	.hit-items .product-item,
	.new-items .new-products__carousel .product-item
		width: 100% !important
		margin-bottom: 20px


	.hit-items__content
		flex-wrap: wrap

	.hit-items .product-item__img-block,
	.new-items .new-products__carousel .product-item__img-block
		padding: 38px 52px

	.hit-items .product-item__img,
	.new-items .new-products__carousel .product-item__img
		width: 225px

	.new-items .new-products__carousel .product-item__link,
	.hit-items .product-item .product-item__link
		width: 50%

		


