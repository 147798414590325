@import color
@import fonts


.response-carousel__user-info
	display: flex
	justify-content: space-between
	border-bottom: 1px solid $color-grey
	padding-bottom: 5px

.response__title-content
	display: flex
	justify-content: space-between
	align-items: baseline
	
.response-carousel__item
	padding: 30px
	background: $color-white
	box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.07)
	border-radius: 12px
	height: 320px
	
.response-carousel__like-icon,
.response-carousel__name-product
	text-align: right

.response-carousel__feedback
	height: 145px
	overflow: hidden 
	padding-top: 20px
	font-size: 15px
	line-height: 21px
	margin-bottom: 18px

.reviews
	background-color: $color-Light_grey
	padding: 80px 0

.response-carousel__user,
.response-carousel__product
	width: 45%

.response-carousel__user-name
	line-height: 24px
	margin-bottom: 10px

.response-carousel__name-product
	font-size: 14px
	line-height: 19px
	display: block
	color: $color-blue
	height: 38px
	overflow: hidden

.response-carousel.owl-carousel .owl-nav.disabled
	display: block

.response-carousel.owl-carousel
	position: relative

.response-carousel.owl-carousel .owl-nav button.owl-next,
.response-carousel.owl-carousel .owl-nav button.owl-prev
	position: absolute
	top: 45%
	transition: all 0.3s ease
	svg
		fill: $color-grey
	&:hover
		svg
			fill: $color-black
			transition: all 0.3s ease
.response-carousel.owl-carousel .owl-nav button.owl-prev
	left: -52px
	
.response-carousel.owl-carousel .owl-nav button.owl-next
	right: -52px


@media (max-width: 1600px)
	.reviews 
		padding: 35px

	.response-carousel__item
		padding: 17px
		height: 280px

	.response-carousel__name-product
		font-size: 11px

	.response-carousel__feedback
		font-size: 12px
		line-height: 17px
		height: 120px


@media (max-width: 690px)
	.reviews
		padding: 120px 0

	.response-carousel.owl-carousel
		display: block
		
	.reviews .container 
		position: relative
		
	.response-carousel__item
		height: 260px
		margin-bottom: 20px

	.response-carousel__link
		text-decoration: none

	.response-carousel__feedback
		margin-bottom: 8px

	.response__link
		position: absolute
		bottom: -68px
		left: 39%
		border: 2px solid $color-black
		color: $color-black
		text-decoration: none
		font-size: 16px
		border-radius: 50px
		padding: 6px 32px


@media (max-width: 440px)
	.response__link
		left: 27%