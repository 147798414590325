@import color
@import fonts

ul
	list-style: none
	padding: 0
	margin: 0

h1, h2, h3, h4, h5, h6, p
	margin: 0
body
	font-family: Manrope, sans-serif
	font-weight: 400
	margin: 0
	overflow-x: hidden
	*
		box-sizing: border-box

html
	overflow-x: hidden
.row
	margin-right: -15px
	margin-left: -15px

.row >*
	padding-right: 15px
	padding-left: 15px

.subtitle
	font-family: BebasNeue, sans-serif
	font-weight: 700
	font-size: 48px
	line-height: 1
	margin-bottom: 40px

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
	padding-right: 15px
	padding-left: 15px

.span-blue
	color: $color-blue

.span-green
	color: $color-green

.span-dark-blue
	
	color: #043E87
.section
	margin-bottom: 100px

.span-dark-grey
	font-size: 14px
	line-height: 19px
	color: $color-Dark_grey

.grey-attantion
	border-radius: 12px
	background-color: $color-Light_grey
	padding: 22px 60px
	font-size: 15px
	line-height: 21px
	display: flex
	align-items: center
	img
		margin-right: 10px

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl 
	max-width: 1320px
	

@media (max-width: 1600px)
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
		max-width: 1000px

	.subtitle
		font-size: 39px
		margin-bottom: 30px

	.span-dark-grey
		font-size: 12px

	.section
		margin-bottom: 70px
	body
		font-size: 15px

	.grey-attantion
		font-size: 13px

	.contacts__link 
		padding: 22px 30px
		font-size: 17px

	.contacts__attantion
		margin-bottom: 45px

@media (max-width: 1090px)
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
		max-width: 800px
	.section
		margin-bottom: 40px

	.span-dark-grey
		line-height: 15px

@media (max-width: 890px)

	.span-dark-grey
		font-size: 11px

@media (max-width: 690px)
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
		max-width: 100%

	.span-dark-grey
		font-size: 12px

	.subtitle 
		font-size: 35px

@media (max-width: 440px)
	.row,
	.row >*
		padding-right: 0
		padding-left: 0

	.span-dark-grey
		font-size: 14px
		line-height: 19px

	.subtitle 
		font-size: 32px
		margin-bottom: 30px

	.section
		margin-bottom: 60px

	.grey-attantion
		padding: 22px
		align-items: flex-start
		font-size: 15px
		line-height: 21px
		


	