@import fonts
@import color

.articles__search-input 
	background: #f5f5f5
	border-radius: 40px
	border: none
	padding: 14px 53px
	font-size: 15px
	line-height: 20px
	color: #888
	font-family: Manrope, sans-serif
	width: 100%

.articles__btn-search 
	border: none
	cursor: pointer
	position: absolute
	top: 16px
	left: 21px
	background: 0 

.articles-header__content
	display: flex
	justify-content: space-between
	align-items: baseline

.articles-header__content-left-col
	display: flex
	justify-content: flex-end

.articles__content-left-col
	&:not(:last-child)
		margin-right: 30px

.articles__search
	position: relative
	width: 50%

.btn.articles-header__link
	padding: 12px 24px

.articles-blog__list
	display: flex
	margin-bottom: 30px 
	flex-wrap: wrap
	border-top: 1px solid $color-grey
	padding-top: 30px

.articles-blog__item
	margin-bottom: 10px
	&:hover a
		color: $color-blue
	&:not(:last-child)
		margin-right: 67px

.articles-blog .blog__content
	flex-wrap: wrap

.articles-blog .blog__item-description
	height: 40px 
	overflow: hidden

.articles-blog .blog__item-title
	font-size: 20px

.articles-blog .blog__item
	margin-bottom: 60px

.articles-blog__link
	width: 100%
	text-align: center

.articles-blog__item.active,
.articles-blog__item.active a
	color: $color-blue

.blog__item.blog__item-top-row
	width: calc( 50% - 15px )

.articles-blog .blog__item-img img
	width: 100%


		
@media (max-width: 1600px)

	.articles-blog__item
		font-size: 13px
		margin-bottom: 7px
		&:not(:last-child)
			margin-right: 45px

	.articles__search-input
		font-size: 13px
		padding: 9px 53px

	.articles-header__content-left-col
		width: 55%

	.articles__content-left-col
		&:not(:last-child)
			margin-right: 15px

	.articles__btn-search
		top: 10px

	.btn.articles-header__link
		padding: 6px 24px

	.articles-blog .blog__item-title
		font-size: 16px

	.articles-blog .blog__item
		margin-bottom: 30px

@media (max-width: 440px)
	.articles-header__content
		position: relative
		padding-bottom: 75px
		align-items: center

	.articles__search.articles__content-left-col
		position: absolute
		bottom: 0
		width: 100%
		margin-right: 0
		padding-bottom: 20px

	.articles-blog__list
		margin-bottom: 20px

	.articles-blog .blog__item-title
		display: block
		margin-bottom: 10px

	.articles-header .subtitle.articles__title
		margin-bottom: 0

	.btn.articles-header__link
		padding: 8px 16px

	.articles-blog__item:not(:last-child)
		margin-right: 12px

	.articles-blog__item
		margin-right: 16px
		font-size: 14px

	.articles-blog .blog__item-img img
		height: 290px

	.blog__item.blog__item-top-row
		width: 100%
