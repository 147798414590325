@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.section-article__content {
  display: flex;
  justify-content: space-between;
}

.description-photo {
  width: 540px;
  padding: 10px;
  border: 1px solid #DFDFDF;
  border-radius: 12px;
}

.section-article .blog__item.blog__item-top-row {
  width: 100%;
}

.section-article__content-left li {
  padding-left: 12px;
  position: relative;
  margin-top: 10px;
}
.section-article__content-left li::before {
  position: absolute;
  width: 4px;
  height: 4px;
  left: 0px;
  top: 9px;
  content: "";
  background-color: #0B76FE;
  border-radius: 50%;
}
.section-article__content-left ul, .section-article__content-left p {
  margin-bottom: 20px;
  font-size: 15px;
}

.social-media {
  padding: 30px 0;
  margin: 30px 0;
  border-bottom: 1px solid #DFDFDF;
  border-top: 1px solid #DFDFDF;
  text-align: center;
}
.social-media h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.social-link {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #161717;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.social-link:not(:last-child) {
  margin-right: 10px;
}
.social-link:hover {
  fill: #ffffff;
  background-color: #161717;
  transition: all 0.3s ease;
}

.social-link-row {
  display: flex;
  justify-content: center;
}

.prev-article img,
.next-article img {
  width: 15px;
}

.prev-article img {
  margin-right: 12px;
}

.next-article img {
  margin-left: 12px;
}

.switching-articles {
  padding-bottom: 30px;
  border-bottom: 1px solid #DFDFDF;
  display: flex;
  justify-content: space-between;
}
.switching-articles a {
  display: block;
}

.section-article .blog__item-img img {
  height: 410px;
  object-fit: cover;
  border-radius: 12px;
}

.section-article .blog__item-description {
  font-size: 16px;
  color: #161717;
  font-weight: 600;
  margin-bottom: 20px;
}

.section-article .blog__item-info {
  margin-bottom: -7px;
}

.section-article .blog__item-img {
  height: auto;
}

.section-article .product-item {
  width: 100%;
  margin-bottom: 30px;
}

.sidebar-product__title {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 20px;
}

.section-article__content-left {
  width: calc(75% - 15px);
}

.sidebar-product {
  width: calc(25% - 15px);
}

@media (max-width: 1600px) {
  .section-article .subtitle {
    margin-bottom: 30px;
  }
  .section-article .blog__item-info {
    margin-bottom: 0;
  }
  .section-article .blog__item-img {
    margin-bottom: 10px;
  }
  .section-article .blog__item-description {
    font-size: 15px;
  }
  .section-article .blog__item-info.span-dark-grey p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .section-article__content-left li {
    padding-left: 10px;
    position: relative;
    margin-top: 7px;
  }
  .section-article__content-left ul, .section-article__content-left p {
    margin-bottom: 15px;
    font-size: 13px;
  }
  .description-photo {
    width: 480px;
  }
  .sidebar-product__title {
    font-size: 16px;
  }
  .social-media {
    margin: 25px 0;
    padding: 25px 0;
  }
  .social-media h4 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .switching-articles {
    font-size: 13px;
    padding-bottom: 25px;
  }
  .social-link {
    width: 42px;
    height: 42px;
  }
}
@media (max-width: 440px) {
  .section-article__content {
    flex-direction: column;
  }
  .section-article__content-left,
  .description-photo,
  .sidebar-product {
    width: 100%;
  }
  .section-article .blog__item-img img {
    height: 290px;
  }
  .section-article__content-left ul {
    font-size: 16px;
  }
  .section-article__content-left p {
    font-size: 15px;
  }
  .switching-articles {
    border-bottom: none;
  }
  .sidebar-product {
    margin-top: 40px;
  }
  .sidebar-product__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}