@import fonts
@import color

.welcome__shop__title-content,
.welcome__shop__description-block,
.about-description__content
	display: flex
	justify-content: space-between

.welcome__shop__description
	font-size: 25px
	width: 90%
	font-weight: 600

.welcome__shop__description-block,
.welcome__shop__title-content
	flex-direction: column

.subtitle.welcome__shop__title
	font-size: 64px
	width: 100%

.welcome__shop__description-block
	width: 41%

.welcome__shop__link-col
	width: 100%
	a
		display: block
		width: 100%
		font-size: 23px
		font-weight: 600
		line-height: 28px
		&:hover
			background-color: $color-green
			color: $color-black
			border: 2px solid $color-green
			transition: all 0.3s ease
		

.welcome__shop .container
	display: flex
	justify-content: space-between

.welcome__shop__title-content
	width: 55%

.welcome__shop__link
	position: relative
	&:not(:last-child)
		margin-bottom: 20px

.welcome__shop__link
	&::after
		position: absolute
		content: ''
		top: 16px
		right: 40px
		background-image: url('/assets/images/svg-icon/vectot-black.svg') 
		width: 14px
		height: 14px

.list-char-main-store
	li
		width: 100%
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 10px
		font-size: 15px
		line-height: 140%
		position: relative
		> *
			background-color: $color-Light_grey
			font-size: 15px
		&::after
			position: absolute
			width: 100%
			bottom: 5px
			z-index: -1
			left: 0
			content: ''
			display: block
			border-bottom: 1px dashed $color-black

.shope-statistics__title
	margin-bottom: 20px
	font-size: 18px

.shope-statistics
	width: 100%
	padding: 30px
	background-color: $color-Light_grey
	z-index: -2
	border-radius: 12px

.about-description__img
	width: 50%
	height: auto
	object-fit: cover
	border-radius: 12px

.about-description__text
	width: 47%
	p
		font-size: 15px
		line-height: 22px
		&:not(:last-child)
			margin-bottom: 30px

.section-images_block-1
	background-image: url('/assets/images/img-banner/images-block-1.jpg') 
	margin-bottom: 20px
	.section-images_block__content
		width: 45%

.section-images_block-2
	background-image: url('/assets/images/img-banner/images-block-2.jpg')
	.section-images_block__content
		display: flex
		justify-content: space-between
		flex-direction: column
		height: 100%
		a
			width: 62%
			line-height: 25px
			text-align: center

.section-images_block-3
	background-image: url('/assets/images/img-banner/images-block-3.jpg')
	.section-images_block__content
		color: $color-white

.section-images_block-4
	background-image: url('/assets/images/img-banner/images-block-4.jpg')
	.section-images_block__content
		color: $color-white

.section-images_block-1,
.section-images_block-2,
.section-images_block-3
	height: 300px

.section-images_block__content a
	font-weight: 600

.images-col
	background-position: center 
	background-repeat: no-repeat
	background-size: cover
	border-radius: 12px
	padding: 40px

.section-images__content,
.section-images__left-col--bottom-row,
.result-advantage__content
	display: flex
	justify-content: space-between

.result-advantage__content
	align-items: center

.section-images__left-col
	width: calc( 70% - 20px )

.section-images__right-col
	width: 30%

.section-images_block-2
	width: calc( 50% - 20px )

.section-images_block-3
	width: 50%

.section-images__title
	font-size: 22px
	line-height: 30px
	margin-bottom: 10px

.section-images__description
	font-size: 16px
	line-height: 19px

.result-advantage__item
	position: relative
	padding-left: 10px
	font-weight: 600
	font-size: 16px
	line-height: 19px

.result-advantage__item
	&:not(:last-child)
		margin-bottom: 14px

.result-advantage__item::before 
	position: absolute
	top: 8px
	left: -10px
	width: 4px
	height: 4px
	background-color: #0b76fe
	content: ""
	border-radius: 50%

.result-advantage__title-content
	width: 41%

.result-advantage__img
	width: 100%
	height: 500px
	object-fit: cover
	border-radius: 12px

.result-advantage__description
	width: 50%


	
@media (max-width: 1600px)
	.welcome__shop__description
		font-size: 20px

	.welcome__shop__link-col a
		font-size: 18px

	.subtitle.welcome__shop__title
		font-size: 48px

	.shope-statistics
		padding: 20px
		
	.shope-statistics__title
		font-size: 15px

	.list-char-main-store
		li
			> *
				font-size: 13px

	.about-description__text
		p
			font-size: 13px
			&:not(:last-child)
				margin-bottom: 20px

	.section-images__title
		font-size: 18px
		line-height: 23px

	.section-images__description,
	.result-advantage__item
		font-size: 13px

	.result-advantage__item
		&:not(:last-child)
			margin-bottom: 10px

	.result-advantage__title-content
		width: 45%

	.welcome__shop__link::after
		top: 11px
		

@media (max-width: 440px)

	.welcome__shop__title-content,
	.welcome__shop__description-block,
	.welcome__shop__description,
	.about-description__text,
	.about-description__img
		width: 100%

	.welcome__shop .container,
	.about-description__content
		flex-direction: column

	.subtitle.about__title
		margin-bottom: 10px

	.subtitle.welcome__shop__title
		font-size: 43px
		margin-bottom: 10px

	.welcome__shop__description 
		font-size: 16px
		line-height: 25px
		margin-bottom: 20px

	.welcome__shop__link-col a
		padding: 16px 40px
		border-radius: 50px

	.welcome__shop__link:not(:last-child)
		margin-bottom: 10px

	.welcome__shop__link::after
		top: 24px

	.welcome__shop__link-col
		margin-bottom: 30px

	.shope-statistics__title
		font-size: 16px

	.about-description__text
		margin-bottom: 40px
		p
			font-size: 15px

	.section.welcome__shop
		margin-bottom: 40px

	.result-advantage__content,
	.section-images__content
		flex-direction: column

	.section-images_block-1 .section-images_block__content,
	.section-images__left-col,
	.section-images_block-3,
	.section-images_block-2,
	.section-images_block-4,
	.result-advantage__title-content,
	.result-advantage__description
		width: 100%

	.section-images_block-1
		background-image: url('/assets/images/img-banner/banner-mobile.jpg')

	.section-images__left-col--bottom-row
		flex-direction: column-reverse

	
	.section-images_block-1, 
	.section-images_block-2,
	.section-images_block-3,
	.section-images_block-4
		height: 320px

	.images-col
		padding: 20px
		margin-bottom: 20px
		background-position: top


	.section-images__title
		font-size: 16px

	.result-advantage__item,
	.section-images__description
		font-size: 14px

	.section-images_block-3 .section-images__description
		width: 90%

	.result-advantage__img
		display: none

	.subtitle.result-advantage__title
		font-size: 40px

	.result-advantage__item
		padding-left: 15px

	.result-advantage__item::before
		left: 0