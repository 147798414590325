@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.about-product__description,
.about-product__item {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 10px;
}

.recommendations__content .product-item__description {
  display: none;
}

.about-product__list {
  padding-left: 10px;
}

.about-product__item {
  position: relative;
}

.about-product__item::before {
  position: absolute;
  top: 8px;
  left: -10px;
  width: 4px;
  height: 4px;
  background-color: #0B76FE;
  content: "";
  border-radius: 50%;
}

.recommendations__content,
.similar-product__content {
  display: flex;
}

.product__views svg {
  fill: #0B76FE;
}

.product__title-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 21px;
}

.product-main-info__images {
  padding: 100px 45px;
  border: 1px solid #DFDFDF;
  border-radius: 12px;
  position: relative;
  width: 32%;
}

.product-main-info__content-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product__views {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
}
.product__views svg {
  margin-right: 5px;
}

.product__title {
  margin-bottom: 0;
  font-size: 42px;
}

.product-main-labels-wrapper {
  position: absolute;
  display: flex;
  top: 20px;
  left: 20px;
  align-items: center;
}

.product-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 4px 12px;
  border-radius: 20px;
}

.product-main-labels-wrapper > * {
  margin-right: 14px;
}

.label-new {
  background: #3EB5D7;
}

.label-hit {
  background: #F14B4B;
}

.product-main-info__info-block {
  display: flex;
  width: calc(68% - 30px);
  border-top: 1px solid #DFDFDF;
  padding-top: 20px;
  justify-content: space-between;
}

.info-block__left-col {
  width: 61%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-main-labels-wrapper img {
  height: 320px;
}

.info-block__right-col {
  width: calc(39% - 30px);
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.info-block__left-col-download-info {
  padding: 13px;
  background-color: #F5F5F5;
  border-radius: 8px;
  border: 1px solid #DFDFDF;
}
.info-block__left-col-download-info svg {
  fill: #888888;
}

.info-block__left-col-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #161717;
  margin-bottom: 20px;
}

.list-char-main-prod {
  width: 100%;
}
.list-char-main-prod li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 140%;
  position: relative;
}
.list-char-main-prod li > * {
  background-color: #fff;
}
.list-char-main-prod li::after {
  position: absolute;
  width: 100%;
  bottom: 4px;
  z-index: -1;
  left: 0;
  content: "";
  display: block;
  border-bottom: 1px dashed #888888;
}

.download_dist_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #0B76FE;
  background: #F5F5F5;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.download_dist_product svg {
  transition: all 0.3s ease;
}

.download_dist_product.open {
  background-color: #ffffff;
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
  transition: all 0.3s ease;
}
.download_dist_product.open svg {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.dropdown {
  position: absolute;
  width: 100%;
  padding: 15px 20px;
  background-color: white;
  border-radius: 0 0 12px 12px;
  border: 1px solid #DFDFDF;
  display: none;
  border-top: none;
}
.dropdown a {
  background-color: #ffffff;
  font-weight: 400;
  color: #161717;
  padding: 10px 0;
  font-size: 14px;
  display: block;
}
.dropdown a:not(:last-child) {
  border-bottom: 1px dashed #DFDFDF;
}
.dropdown a:first-child {
  border-top: 1px solid #DFDFDF;
}

.info-block__left-col-payment-methods {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.info-block__left-col-payment-methods img {
  margin-right: 8px;
  margin-bottom: 8px;
  flex-shrink: 0;
  height: 22px;
}

.product-main-info__radio-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  justify-content: space-between;
}

.product-main-info__radio-wrapper-item {
  width: calc(50% - 10px);
}
.product-main-info__radio-wrapper-item a {
  display: flex;
  align-items: center;
  height: 53px;
  position: relative;
  width: 100%;
  background: #F5F5F5;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  padding: 12px 10px;
}
.product-main-info__radio-wrapper-item a b {
  margin-right: 5px;
}
.product-main-info__radio-wrapper-item a::before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  margin-right: 8px;
}

.product-main-info__radio-wrapper-item.active a::before {
  background-color: #0B76FE;
  border-color: #0B76FE;
}

.product-main-info__radio-wrapper-item.active a::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 14px;
  display: block;
  background-image: url(/assets/images/svg-icon/check.svg);
  width: 12px;
  height: 10px;
}

.product-main-info__buy {
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
  background: #f5f5f5;
  border: 1px solid #dfdfdf;
  text-align: center;
  border-radius: 12px;
  justify-content: center;
  height: 100%;
  display: flex;
}

.product-main-info__buy-price {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-main-info__buy-price .new-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: #161717;
  margin-right: 12px;
  letter-spacing: -0.05em;
}
.product-main-info__buy-price .old-price {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.05em;
  text-decoration-line: line-through;
  color: #888888;
}

.product-main-info--buy-content .btn {
  width: 100%;
  padding: 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.product-main-info--buy-content .buy-one-click-button {
  margin-bottom: 15px;
}
.product-main-info--buy-content .add-to-cart {
  margin-bottom: 6px;
}
.product-main-info--buy-content .quan {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.product-main-info--buy-content .control {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-main-info--buy-content .item-counter {
  width: 40px;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #161717;
}
.product-main-info--buy-content .how-to-buy {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0B76FE;
  margin-bottom: 12px;
  display: inline-block;
}
.product-main-info--buy-content .info {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #888888;
}
.product-main-info--buy-content .info a {
  color: #0B76FE;
}

.tab {
  display: none;
}

.tab.active {
  display: block;
}

.product-main-info {
  margin-bottom: 60px;
}

.tabs-head {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #DFDFDF;
  margin-bottom: 40px;
}

.tab-link {
  width: 25%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #888888;
  border-radius: 12px 12px 0px 0px;
}

.tab-link.active {
  background: #0B76FE;
  color: #fff;
}

.tabs-content {
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.tabs-content h6 {
  color: #F14B4B;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 20px;
}

.tabs-content p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 21px;
}

.tabs-content .info {
  padding: 24px 100px;
  margin: 20px 0;
  background: #F5F5F5;
  border-radius: 12px;
  display: flex;
}
.tabs-content .info img {
  flex-shrink: 0;
  margin-right: 8px;
}

.review-tab .response-carousel__feedback {
  height: 65px;
}

.review-tab .response-carousel__item {
  height: auto;
  margin-bottom: 40px;
}

.question-faq_page {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.question-faq_page .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
  background-color: #F5F5F5;
  position: relative;
  transition: all 0.3s ease;
}

.question-faq_page .icon::after {
  content: "";
  background-image: url(/assets/images/svg-icon/plus-cross.svg);
  background-repeat: no-repeat;
  display: block;
  width: 16px;
  height: 16px;
}

.answer-faq_page {
  padding-top: 20px;
  border-top: 1px solid #DFDFDF;
  margin-top: 10px;
  display: none;
}

.active .question-faq_page .icon::before {
  transform: rotate(90deg);
}

.faq-tab .item {
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
}

.faq-tab .active .icon {
  transform: rotate(45deg);
  background-color: #67E722;
}

.info-block__left-col-download__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-block__left-col-download {
  width: 88%;
  position: relative;
}

.info-block__left-col-download-info__text {
  display: none;
  position: absolute;
  width: 306px;
  font-size: 12px;
  line-height: 17px;
  background: #ffffff;
  border-radius: 8px 8px 0px 8px;
  padding: 16px;
  top: -90px;
  left: -260px;
}

.info-block__left-col-download-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-info-download {
  position: relative;
}

.tab-response-link {
  width: 100%;
  text-align: center;
}

@media (max-width: 1600px) {
  .product__title {
    font-size: 38px;
  }
  .product__views,
  .list-char-main-prod li {
    font-size: 12px;
  }
  .product-label {
    font-size: 8px;
  }
  .info-block__left-col-title {
    font-size: 17px;
    margin-bottom: 15px;
  }
  .product-main-info__radio-wrapper-item label {
    padding: 9px 6px;
    margin-bottom: 10px;
    height: 41px;
  }
  .product-main-info__buy-price .new-price {
    font-size: 24px;
  }
  .product-main-info__buy {
    padding: 15px;
  }
  .info-block-char-wrapper {
    margin-bottom: 0;
  }
  .product-main-info__images img {
    width: 250px;
  }
  .info-block__left-col-download a {
    padding: 10px;
  }
  .product-main-info__radio-wrapper-item label b,
  .product-main-info__radio-wrapper-item .span-dark-grey {
    font-size: 11px;
  }
  .product-main-info__radio-wrapper-item input:checked + label:after {
    top: 16px;
    left: 11px;
  }
  .product-main-info__radio-wrapper {
    margin-bottom: 5px;
  }
  .product-main-info__buy-price {
    margin-bottom: 10px;
  }
  .product-main-info--buy-content .btn {
    line-height: 16px;
    font-size: 13px;
  }
  .product-main-info--buy-content .info {
    font-size: 9px;
  }
  .tab-link {
    font-size: 16px;
  }
  .tabs-content p {
    font-size: 14px;
  }
  .about-product__description, .about-product__item {
    font-size: 14px;
  }
  .product-item__description {
    font-size: 14px;
  }
  .about-product__description, .about-product__item, .product-item__description, .tabs-content p {
    font-size: 12px;
  }
  .info-block__left-col-payment-methods img {
    height: 16px;
  }
  .info-block__left-col {
    width: 59%;
  }
  .info-block__right-col {
    width: calc(41% - 20px);
  }
  .product-main-info__info-block {
    width: calc(68% - 20px);
  }
  .info-block__left-col-download-info {
    padding: 10px;
  }
  .info-block__left-col-download {
    width: 85%;
  }
  .dropdown a {
    font-size: 12px;
  }
  .product-main-info__radio-wrapper-item {
    width: calc(50% - 5px);
  }
}
@media (min-width: 440px) {
  .tooltip-info-download:hover .info-block__left-col-download-info__text {
    display: block;
  }
  .tooltip-info-download:hover svg {
    fill: #0B76FE;
  }
}
@media (max-width: 440px) {
  .product__title-content {
    flex-wrap: wrap;
  }
  .product__title {
    margin-bottom: 10px;
  }
  .product-main-info__content-block,
  .tabs-head,
  .product-main-info__info-block {
    flex-direction: column;
  }
  .product-main-info__info-block,
  .info-block__right-col,
  .info-block__left-col,
  .tab-link,
  .product-main-info__images {
    width: 100%;
  }
  .product-main-info__images {
    padding: 55px 37px 30px 37px;
  }
  .recommendations__content,
  .similar-product__content {
    flex-wrap: wrap;
  }
  .recommendations__content .product-item,
  .similar-product__content .product-item {
    margin-bottom: 20px;
  }
  .recommendations__content .product-item__title,
  .similar-product__content .product-item__title {
    font-size: 16px;
    font-weight: 600;
  }
  .info-block__left-col-download {
    width: 82%;
  }
  .product-main-info__content-block {
    position: relative;
    padding-bottom: 220px;
  }
  .info-block__left-col-payment {
    position: absolute;
    bottom: -50px;
    border-top: 1px solid #DFDFDF;
    padding-top: 10px;
  }
  .info-block__left-col-payment-methods {
    justify-content: flex-start;
  }
  .info-block__left-col-payment-methods img {
    margin-right: 26px;
    margin-bottom: 9px;
    height: 30px;
  }
  .info-block__left-col-download__block {
    margin-bottom: 20px;
  }
  .info-block-char-wrapper {
    margin-bottom: 10px;
  }
  .product-main-info__radio-wrapper-item label b {
    margin-right: 10px;
  }
  .product-main-info__radio-wrapper-item .span-dark-grey,
  .product-main-info__radio-wrapper-item label b {
    font-size: 15px;
  }
  .product-main-info__buy-price .new-price {
    font-size: 32px;
  }
  .product-main-info--buy-content .btn {
    line-height: 22px;
    font-size: 16px;
    padding: 9px 77px;
  }
  .product-main-info--buy-content .info {
    font-size: 12px;
  }
  .tab-link {
    background-color: #F5F5F5;
    padding: 11px 100px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .about-product__description, .about-product__item,
  .product-item__description,
  .tabs-content p {
    font-size: 15px;
  }
  .tabs-content .info {
    padding: 24px 20px;
    align-items: flex-start;
  }
  .product-main-info {
    margin-bottom: 85px;
  }
  .tabs-content {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .about-product__subtitle {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .faq-tab .item {
    padding: 13px;
    margin-bottom: 10px;
  }
  .question-faq_page {
    width: 80%;
    align-items: flex-start;
  }
  .question-faq_page .icon {
    position: absolute;
    right: 30px;
  }
  .answer-faq_page {
    line-height: 22px;
  }
  .review-tab .response-carousel__feedback {
    height: 115px;
  }
  .dropdown {
    z-index: 10;
    padding-top: 0;
  }
  .dropdown a {
    font-size: 14px;
  }
}