@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.header__btn-my-buy,
.header__btn-cart {
  text-transform: uppercase;
  position: relative;
}

.header__top-block {
  background-color: #0B76FE;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.header__top-row {
  align-items: center;
}

.header__top-row--left-col {
  color: #ffffff;
  font-size: 14px;
}

.header__currency {
  display: flex;
  align-items: center;
  color: #ffffff;
  position: relative;
}
.header__currency svg {
  margin-left: 8px;
  transition: all 0.3s ease;
  fill: #ffffff;
  cursor: pointer;
}

.current-currency-click::after {
  position: absolute;
  width: 8px;
  height: 30px;
  right: 13px;
  top: 0;
  content: "";
  cursor: pointer;
}

.header__currency.opened svg {
  transition: all 0.3s ease;
  transform: rotate(180deg);
}

.header__currency--title,
.header__currency--flag {
  margin-right: 8px;
}

.header__currency--flag svg {
  transform: translateY(2.5px);
  border-radius: 2px;
}

#currency_switcher {
  background-color: #0B76FE;
  color: #ffffff;
  border: none;
}
#currency_switcher:focus-visible {
  outline: none;
}

.header__top-block .container {
  position: relative;
}

.header__content-cart {
  position: absolute;
  top: 45px;
  right: 1%;
  padding: 25px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.4);
  z-index: 40;
  width: 520px;
}

.header__content-cart__title-row,
.header__content-cart__product-row,
.header__content-cart__result-row,
.header__content-cart__product-price-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__content-cart-title {
  font-size: 20px;
}

.header__content-cart {
  display: none;
}

.remover-cart svg {
  fill: #F14B4B;
}

.header__content-cart__product-row {
  padding-top: 15px;
  padding-bottom: 15px;
}

.header__content-cart__title-row,
.header__content-cart__product-row {
  border-bottom: 1px solid #DFDFDF;
}

.header__content-cart__result-row {
  padding-top: 20px;
}

.header__content-cart__title-row {
  padding-bottom: 15px;
}

.header__content-cart__product-title {
  width: 310px;
}

.remover-product {
  color: #0B76FE;
  font-size: 14px;
}

.span-quantity {
  padding-left: 5px;
}

.span-product-price {
  padding-right: 5px;
}

.header__content-cart__product-left-block {
  text-align: end;
}

.header__content-cart-link:first-child {
  margin-right: 10px;
}

.btn-my-buy,
.btn-cart {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
  letter-spacing: 0.05em;
  line-height: 1;
}

.btn-my-buy svg,
.btn-cart svg {
  margin-right: 4px;
  transition: all 0.3s ease;
}

.child-list {
  display: none;
  position: absolute;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #DFDFDF;
  left: -20px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  width: 160px;
  height: auto;
  top: 30px;
}

.has-child::after {
  content: "";
  position: absolute;
  background-image: url("/assets/images/svg-icon/Chevron_Right_1_.svg");
  top: 12px;
  right: 0;
  width: 12px;
  height: 8px;
  transition: all 0.3s ease;
}

.header__sub-nav--sub-link {
  font-size: 15px;
  color: #161717;
  line-height: 20px;
}

.header__logo-img {
  width: 255px;
}

.has-child {
  position: relative;
}

.header__sub-nav--sub-item:not(:last-child) {
  margin-bottom: 10px;
}

.header__sub-nav--list {
  justify-content: space-between;
  font-weight: 600;
  line-height: 22px;
}

.header__sub-nav--item {
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header__sub-nav--item:not(:last-child) {
  padding-right: 30px;
}

.header__sub-nav--item a {
  color: #161717;
}

.header__sub-nav--sub-list {
  font-weight: 400;
}

.quantity-span {
  position: absolute;
  top: 1px;
  left: 25px;
  color: #ffffff;
  border-radius: 50%;
  font-size: 9px;
  padding: 0 6px;
  background-color: #F14B4B;
}

.header__btn-cart {
  position: relative;
}

.header__center-row {
  align-items: center;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 3px;
}

.header__center-block {
  margin-bottom: 20px;
}

.header__search-input {
  background: #F5F5F5;
  border-radius: 40px;
  border: none;
  padding: 10px 50px;
  font-size: 15px;
  line-height: 20px;
  color: #888888;
  font-family: Manrope, sans-serif;
  width: 100%;
}

.header__btn-search {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 11px;
  left: 28px;
}

.header__search {
  position: relative;
}

.header__product-nav--sub-link {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.header__product-nav--sub-img {
  margin-right: 8px;
  width: 24px;
}

.header__product-nav--sub-list {
  margin-bottom: 20px;
}

.header__product-nav--link {
  color: #ffffff;
  background-color: #0B76FE;
  border-radius: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  padding: 27px 30px;
  display: block;
}

.nav-has-child {
  position: relative;
}

.nav-has-child::after {
  content: "";
  position: absolute;
  top: 34px;
  right: 50px;
  width: 13px;
  height: 13px;
  background-image: url("/assets/images/svg-icon/cersor.svg");
  transition: all 0.3s ease;
}

.header__product-nav--sub-list:first-child {
  border-top: 1px solid #DFDFDF;
  padding-top: 30px;
}

.btn-my-buy__span-grey {
  border-radius: 12px;
  background-color: #F5F5F5;
  padding: 20px;
  font-size: 14px;
  line-height: 19px;
  text-transform: initial;
  width: 340px;
  display: none;
  position: absolute;
  top: 43px;
  right: 0;
  z-index: 1;
}

.burger-menu__content {
  display: none;
}

.current-currency,
.currency {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.current-currency img,
.currency img {
  width: 22px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}

.header__currency--dropdown {
  width: 100px;
  background-color: #0B76FE;
  border-radius: 0 0 12px 12px;
  position: absolute;
  top: 26px;
  z-index: 10;
  left: 56px;
  padding: 12px;
  display: none;
}

@media (max-width: 1600px) {
  .header__top-block {
    height: 40px;
    margin-bottom: 12px;
  }
  .header__top-row--left-col {
    font-size: 12px;
  }
  .header__currency--flag,
  .header__currency--title,
  .header__sub-nav--list {
    font-size: 13px;
  }
  .btn-cart, .btn-my-buy {
    font-size: 10px;
    padding: 4px 8px;
    line-height: 4px;
  }
  .header__sub-nav--item:not(:last-child) {
    padding-right: 20px;
  }
  .header__search-input {
    padding: 6px 44px;
    font-size: 12px;
  }
  .header__btn-search {
    top: 6px;
    left: 26px;
  }
  .header__logo-img {
    width: 215px;
  }
  .header__product-nav--link {
    font-size: 16px;
    padding: 20px 33px;
  }
  .header__center-block {
    margin-bottom: 12px;
  }
  .nav-has-child::after {
    top: 25px;
    right: 37px;
  }
  .header__sub-nav--sub-link {
    font-size: 13px;
  }
  .header__sub-nav--sub-item:not(:last-child) {
    margin-bottom: 6px;
  }
  .child-list {
    padding: 15px;
  }
  .header__product-nav--sub-img {
    width: 20px;
  }
  .nav-list-child-has-child > ul {
    left: calc(100% + 9px);
  }
  .nav-has-child:hover .nav-list-child {
    padding: 0 20px 20px !important;
  }
  .header__product-nav--sub-link {
    font-size: 12px;
  }
  .btn-my-buy__span-grey {
    padding: 15px;
    font-size: 13px;
    line-height: 17px;
    top: 35px;
  }
  .header__content-cart {
    top: 36px;
    width: 460px;
  }
  .quantity-span {
    top: 0px;
    left: 16px;
  }
}
@media (max-width: 1090px) {
  .nav-has-child::after {
    top: 28px;
    right: 27px;
  }
  .header__logo-img {
    width: 160px;
  }
  .header__top-row--left-col {
    font-size: 11px;
  }
  .header__currency--flag,
  .header__currency--title,
  .header__sub-nav--list {
    font-size: 11px;
  }
  .header__currency--dropdown select {
    font-size: 11px;
  }
  .btn-cart,
  .btn-my-buy {
    font-size: 8px;
  }
  .btn-cart svg,
  .btn-my-buy svg {
    width: 11px;
    height: 11px;
  }
  .header__currency--flag svg {
    width: 16px;
    height: 12px;
  }
  .header__top-row--right-col {
    align-items: center;
  }
  .header__currency--flag,
  .header__currency--title {
    margin-right: 5px;
  }
  #currency_switcher {
    transform: translateY(-1px);
  }
  .header__btn-search {
    top: 9px;
    left: 24px;
  }
  .header__btn-search svg {
    width: 12px;
    height: 12px;
  }
  .header__search-input {
    padding: 6px 34px;
    font-size: 11px;
  }
  .header__product-nav--link {
    font-size: 16px;
    padding: 15px 30px;
  }
  .nav-has-child::after {
    top: 22px;
    right: 33px;
  }
  .header__content-cart-title {
    font-size: 16px;
  }
  .header__content-cart__product-title {
    width: 250px;
    font-size: 14px;
  }
  .header__content-cart__title-row {
    padding-bottom: 10px;
  }
  .header__content-cart__product-row {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .header__content-cart__result-row {
    padding-top: 15px;
  }
  .header__content-cart {
    width: 410px;
  }
  .quantity-span {
    top: -1px;
    left: 13px;
    font-size: 7px;
    padding: 0 4px;
  }
}
@media (min-width: 440px) {
  .nav-sublist-child {
    display: none;
  }
  .Windows:hover .nav-sublist-child {
    display: block;
    position: absolute;
  }
  .nav-list-child-has-child {
    position: relative;
  }
  .nav-list-child-has-child::after {
    content: "";
    width: 30px;
    height: calc(100% + 15px);
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
  }
  .nav-list-child-has-child > ul {
    display: none;
    position: absolute;
    left: calc(100% + 20px);
    top: 0;
    width: calc(100% + 60px);
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
  }
  .nav-list-child-has-child:hover > ul {
    display: block;
  }
  .nav-list-child-has-child:hover::after {
    display: block;
    cursor: pointer;
  }
  .has-child:hover .child-list {
    display: block;
    z-index: 10;
  }
  .nav-list-child li:hover > a,
  .header__sub-nav--sub-link:hover {
    color: #0B76FE;
    text-decoration: underline;
  }
  .header__product-nav--sublist:not(:last-child) {
    margin-bottom: 20px;
  }
  .nav-has-child:hover::after {
    transition: all 0.3s ease;
    transform: rotate(80deg);
  }
  .nav-has-child:hover .nav-list-child {
    display: block;
    z-index: 10;
    padding: 0 30px 30px 30px;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.07);
    position: absolute;
    width: calc(100% - 30px);
  }
  .nav-has-child:hover .header__product-nav--link {
    color: #0B76FE;
    border-radius: 12px 12px 0 0;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.07);
  }
  .nav-list-child {
    display: none;
  }
  .nav-has-child:hover .header__product-nav--link, .nav-list-child {
    background-color: #ffffff;
  }
  .has-child:hover::after {
    transform: rotate(180deg);
    transition: all 0.3s ease;
  }
  .has-child::after {
    content: "";
    position: absolute;
    background-image: url("/assets/images/svg-icon/Chevron_Right_1_.svg");
    top: 12px;
    right: 0;
    width: 12px;
    height: 8px;
    transition: all 0.3s ease;
  }
  .header__btn-my-buy:hover .btn-my-buy__span-grey {
    display: block;
  }
}
@media (max-width: 890px) {
  .col-md-6.header__sub-nav {
    width: 100%;
  }
  .col-md-5.header__top-row--right-col,
  .col-md-7.header__top-row--left-col,
  .col-md-3.header__search,
  .col-md-3.header__logo,
  .header__product-nav--item.col-md-3 {
    width: 50%;
  }
  .header__product-nav--item {
    margin-bottom: 20px;
  }
  .nav-list-child-has-child > ul {
    position: relative;
    width: auto;
    border-radius: 0;
    box-shadow: none;
    left: 0;
    padding-bottom: 10px;
  }
  .header__top-row--left-col {
    font-size: 8px;
  }
  .header__currency--title {
    font-size: 9px;
  }
}
@media (max-width: 609px) {
  .nav-has-child:hover .nav-list-child {
    width: calc(100% - 20px);
  }
  .col-md-7.header__top-row--left-col {
    width: 30%;
  }
  .col-md-5.header__top-row--right-col {
    width: 70%;
  }
}
@media (max-width: 440px) {
  .col-md-7.header__top-row--left-col,
  .btn.btn-green.btn-my-buy,
  .col-md-6.header__sub-nav,
  .header__product-nav {
    display: none;
  }
  .burger-menu__content .header__top-row--left-col,
  .burger-menu__content .header__product-nav {
    display: block;
  }
  .burger-menu__content .btn.btn-green.btn-my-buy {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 8px 20px;
    margin-bottom: 12px;
  }
  .burger-menu__content .header__top-row--left-col {
    color: #161717;
    padding-top: 30px;
    font-size: 14px;
  }
  .span-last-buy {
    color: #888888;
  }
  .burger-menu__content .header__sub-nav--list {
    flex-direction: column;
  }
  .burger-menu__content .header__product-nav--link {
    background-color: #ffffff;
    color: #161717;
    padding: 0;
    margin-bottom: 0;
    line-height: 22px;
  }
  .burger-menu__content .header__product-nav--item:not(:last-child) {
    margin-bottom: 23px;
  }
  .header__product-nav--item {
    margin-bottom: 0;
  }
  .burger-menu__content .nav-has-child::after {
    top: 5px;
    right: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 12px;
    width: 12px;
  }
  .burger-menu__content .header__product-nav {
    padding: 30px 0;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
  }
  .header__logo-img {
    width: 180px;
  }
  .col-md-5.header__top-row--right-col {
    width: 100%;
  }
  .btn-cart {
    font-size: 12px;
    padding: 5px 16px;
  }
  .btn-cart svg, .btn-my-buy svg {
    width: 16px;
    height: 16px;
  }
  .header__currency--title,
  #currency_switcher {
    font-size: 14px;
  }
  #currency_switcher {
    transform: translateY(1px);
  }
  .header__center-block,
  .header__top-block {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header__search-input {
    padding: 0;
    width: 0;
  }
  .header__btn-search {
    left: -24px;
    top: 1px;
  }
  .header__btn-search svg {
    width: 16px;
    height: 16px;
  }
  .col-md-3.header__logo {
    width: 85%;
  }
  .col-md-3.header__search {
    width: 0%;
  }
  .header__center-row {
    display: flex;
    justify-content: space-between;
  }
  .header__sub-nav--item {
    font-size: 16px;
    height: auto;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .header__sub-nav--item:not(:last-child) {
    margin-bottom: 20px;
  }
  .child-list {
    box-shadow: none;
    border: none;
    position: relative;
    padding: 10px 0 0 0;
    width: 100%;
    top: 0;
    left: 0;
  }
  .has-child::after {
    height: 7px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 9px;
    margin-left: 20px;
  }
  .header__sub-nav {
    padding: 30px 0;
    border-bottom: 1px solid #DFDFDF;
  }
  .header__product-nav--sub-list:first-child {
    border: none;
    padding-top: 20px;
  }
  .nav-has-child:hover .header__product-nav--link {
    box-shadow: none;
    border-radius: 0;
  }
  .nav-list-child-has-child > ul {
    padding: 20px;
    padding-bottom: 0;
  }
  .header__product-nav--sublist:not(:last-child) {
    margin-bottom: 10px;
  }
  .btn-my-buy__span-grey {
    top: 38px;
    width: 100%;
  }
  .burger-menu__content {
    display: none;
  }
  .burger-menu {
    width: 20px;
    height: 18px;
    position: relative;
    display: block;
    transition: all 0.4s ease;
  }
  .burger-menu span {
    width: 100%;
    height: 2px;
    background-color: #161717;
    position: absolute;
    left: 0;
    top: 2px;
    transition: all 0.4s ease;
  }
  .burger-menu::after {
    content: "";
    width: 100%;
    height: 1.5px;
    background-color: #161717;
    position: absolute;
    bottom: 2px;
    left: 0;
    transition: all 0.4s ease;
  }
  .burger-menu::before {
    content: "";
    width: 100%;
    height: 1.5px;
    background-color: #161717;
    position: absolute;
    top: 8px;
    left: 0;
    transition: all 0.4s ease;
  }
  .burger-menu.open-menu span {
    opacity: 0;
    transition: all 0.4s ease;
  }
  .burger-menu.open-menu::before {
    transform: rotate(45deg);
    top: 8px;
    transition: all 0.4s ease;
  }
  .burger-menu.open-menu::after {
    transform: rotate(-45deg);
    bottom: 8.5px;
    transition: all 0.4s ease;
  }
  .burger-menu__content.open-menu {
    display: block;
  }
  .header__product-nav--sub-list:not(:last-child) {
    margin-bottom: 15px;
  }
  .nav-has-child > ul, .nav-list-child-has-child > ul {
    display: none;
  }
  .nav-has-child:hover .nav-list-child {
    padding: 0 !important;
  }
  .header__product-nav--sub-list {
    margin-bottom: 0;
  }
  .has-child:hover::after {
    transform: none;
  }
  .child-list {
    order: 2;
    width: 100%;
  }
  .nav-has-child.opened .header__product-nav--link {
    color: #0B76FE;
  }
  .nav-has-child.opened::after {
    transform: rotate(90deg);
    transition: all 0.3s ease;
  }
  .has-child.opened::after {
    transform: rotate(180deg);
    transition: all 0.3s ease;
  }
  .header__content-cart {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    top: 110px;
  }
  .header__content-cart-title {
    font-size: 20px;
  }
  .header__content-cart__product-row {
    padding-bottom: 15px;
    padding-top: 15px;
    line-height: 19px;
  }
  .header__content-cart__result-row {
    align-items: flex-start;
    padding-top: 15px;
    flex-direction: column;
  }
  .header__content-cart__result {
    margin-bottom: 20px;
  }
  .header__content-cart-links {
    display: flex;
    width: 100%;
  }
  .header__content-cart-link {
    width: 50%;
    text-align: center;
  }
  .quantity-span {
    top: -1px;
    left: 23px;
    font-size: 9px;
    padding: 0 6px;
  }
  .btn-cart svg {
    margin-right: 8px;
  }
}