@import color
@import fonts

.content-404
	padding: 20px 100px

.logo-404
	text-align: center

.mail-img-404
	img
		width: 100%

.btn.btn-green.link-404
	padding: 19px 82px
	border-radius: 50px

.link-404-block
	text-align: center

@media (max-width: 1600px)
	.btn.btn-green.link-404
		padding: 13px 55px

	
@media (max-width: 992px)
	.btn.btn-green.link-404
		padding: 8px 55px

	.content-404 
		padding: 20px 45px

@media (max-width: 768px)
	.content-404
		padding: 20px


@media (max-width: 440px)
	.logo-404
		margin-bottom: 25px

	.link-404-block
		margin-top: 20px