@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.how-to-buy__first-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.first-block__subtitle {
  font-size: 20px;
  margin-bottom: 10px;
}

.first-block__subtitle-text,
.first-block__description {
  font-size: 15px;
  line-height: 21px;
}

.first-block__subtitle-text {
  font-weight: 600;
  margin-bottom: 20px;
}

.first-block__img img {
  width: 100%;
}

.first-block__title-content {
  width: calc(45% - 30px);
}

.first-block__img {
  width: calc(55% - 30px);
}

.subtitle.how-to-buy__title {
  margin-bottom: 30px;
}

.info-attantion {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 50px;
  background-color: #F5F5F5;
  border-radius: 12px;
  margin-bottom: 50px;
}
.info-attantion p {
  font-size: 15px;
  margin-left: 8px;
}

.choise-pay__title {
  font-size: 15px;
  height: 100px;
  line-height: 21px;
}

.choise-pay__block {
  padding: 40px;
  background-color: #F5F5F5;
  border-radius: 12px;
  width: calc(50% - 15px);
}

.choise-pay__img {
  width: 70%;
}

.choise-pay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.payment-commission__description {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 40px;
}

.payment-commission__table {
  border-collapse: collapse;
  overflow: hidden;
  border-radius: 12px;
  border: none;
  width: 100%;
}
.payment-commission__table td {
  border-top: 1px solid #DFDFDF;
  border-right: 1px solid #DFDFDF;
  font-weight: 600;
}
.payment-commission__table thead td {
  padding: 14px 32px;
  color: #ffffff;
  background-color: #0B76FE;
}
.payment-commission__table tbody td {
  padding: 6px 30px;
  background-color: #F5F5F5;
}

.new-payment__img {
  height: 32px;
}

.new-payment__logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.new-payment__logo-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 110px;
  margin-right: 10px;
}

.new-payment__attantion {
  font-size: 15px;
  display: flex;
  background-color: #F5F5F5;
  border-radius: 12px;
  padding: 22px 60px;
  justify-content: center;
  align-items: center;
  line-height: 21px;
}
.new-payment__attantion img {
  margin-right: 8px;
}

.span-red {
  color: #F14B4B;
}

.new-payment__title {
  font-size: 20px;
  margin-bottom: 30px;
}

.payment-commission__table-block,
.new-payment {
  width: calc(50% - 15px);
}

.payment-commission__content,
.new-payment {
  display: flex;
  justify-content: space-between;
}

.new-payment {
  flex-direction: column;
}

.payment-commission__content {
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .first-block__description,
  .first-block__subtitle-text {
    font-size: 11px;
    line-height: 16px;
  }
  .first-block__subtitle {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .first-block__subtitle-text {
    margin-bottom: 15px;
  }
  .how-to-buy__first-block {
    margin-bottom: 45px;
  }
  .info-attantion {
    padding: 28px 25px;
  }
  .info-attantion p {
    font-size: 11px;
  }
  .choise-pay__title {
    font-size: 12px;
    height: 80px;
    line-height: 17px;
  }
  .payment-commission__description {
    font-size: 13px;
    line-height: 17px;
  }
  .new-payment__title,
  .new-payment__logo {
    font-size: 15px;
    line-height: 20px;
  }
  .payment-commission__table {
    font-size: 13px;
  }
  .payment-commission__table thead td {
    padding: 10px 30px;
  }
  .payment-commission__table tbody td {
    padding: 4px 30px;
  }
  .new-payment__img {
    height: 27px;
  }
  .new-payment__logo {
    margin-bottom: 10px;
  }
  .new-payment__title {
    margin-bottom: 25px;
  }
  .new-payment__attantion {
    padding: 20px 45px;
    font-size: 14px;
  }
}
@media (max-width: 440px) {
  .how-to-buy__first-block,
  .choise-pay,
  .payment-commission__content {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .first-block__title-content,
  .first-block__img,
  .choise-pay__block,
  .choise-pay__img,
  .new-payment,
  .payment-commission__table-block {
    width: 100%;
  }
  .how-to-buy__title.subtitle {
    margin-bottom: 20px;
  }
  .first-block__description,
  .first-block__subtitle-text,
  .payment-commission__description {
    font-size: 15px;
    line-height: 21px;
  }
  .info-attantion {
    align-items: flex-start;
  }
  .info-attantion p {
    line-height: 21px;
    font-size: 15px;
  }
  .choise-pay__block {
    padding: 20px;
  }
  .choise-pay__block:not(:last-child) {
    margin-bottom: 20px;
  }
  .choise-pay__title {
    font-size: 14px;
    height: auto;
    line-height: 19px;
    margin-bottom: 15px;
  }
  .choise-pay,
  .payment-commission__content {
    margin-bottom: 60px;
  }
  .payment-commission__table-block {
    margin-bottom: 20px;
  }
  .new-payment__logo,
  .new-payment__title {
    font-size: 16px;
    line-height: 20px;
  }
  .new-payment__logo-wrapper {
    justify-content: flex-start;
    width: auto;
    margin-right: 20px;
    font-size: 20px;
  }
  .new-payment__img {
    height: 32px;
  }
  .new-payment__logo {
    margin-bottom: 18px;
  }
  .new-payment__attantion {
    align-items: flex-start;
    padding: 22px 24px;
    font-size: 15px;
  }
}