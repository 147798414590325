@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Light.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Medium.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-SemiBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-Bold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff") format("woff");
  src: url("../fonts/Manrope/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueLight.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueRegular.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/Bebas/BebasNeueBold.woff") format("woff");
  src: url("../fonts/Bebas/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
.windows__choice-link {
  background-color: #F5F5F5;
  border-radius: 50px;
  padding: 12px 24px;
  color: #888888;
  line-height: 22px;
  font-weight: 600;
}

.windows__choice-link:hover,
.windows__choice-link:active {
  color: #161717;
  background-color: #67E722;
  transition: all 0.3s ease;
}

.windows__choice-item:not(:last-child) {
  margin-right: 20px;
}

.windows__choice-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.windows__sorting-title {
  font-size: 20px;
  line-height: 28px;
}

.windows__sorting-label {
  margin-right: 20px;
}

#sorting-products {
  font-size: 15px;
  padding: 15px 20px;
  width: 300px;
  line-height: 21px;
  border: 1px solid #DFDFDF;
}

.windows__sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.all-products .product-item {
  margin-bottom: 30px;
}

.all-products__content {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 890px) {
  .windows__choice-item {
    margin-bottom: 10px;
  }
  .windows__sorting-row {
    width: 50%;
  }
}
@media (max-width: 690px) {
  .response .container {
    position: relative;
    padding-bottom: 100px;
  }
  .all-products .product-item {
    width: 48%;
  }
  .all-products .product-item:not(:nth-child(4n)) {
    margin-right: 0;
  }
  .all-products .product-item:not(:nth-child(2n)) {
    margin-right: 20px;
  }
}
@media (max-width: 440px) {
  .windows__choice-link {
    font-size: 14px;
    padding: 8px 16px;
  }
  .windows__choice-item {
    margin-bottom: 8px;
  }
  .windows__choice-item:not(:last-child) {
    margin-right: 12px;
  }
  .windows__choice-list {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .windows__sorting-title {
    display: none;
  }
  .windows__sorting-row {
    display: flex;
    align-items: center;
  }
  #sorting-products {
    width: 170px;
  }
  .windows__sorting-label {
    margin-right: 55px;
  }
  .response .response__link {
    bottom: 30px;
  }
  .all-products .product-item {
    width: 100%;
  }
  .all-products .product-item:not(:nth-child(2n)) {
    margin-right: 0;
  }
}